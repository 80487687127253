import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import createAccess from '../../utils/functions/createAccess';
// import b64toBlob from '../../utils/functions/b64toBlob';
import API from '../../utils/api';

// VARIABLES
export const initialState = {
  user: null,
  fetching: false,
  fetchingChats: false,
  fetchingInternalChats: false,
  operation: null,
  supervision: null,
  internal: null,
  showNotificationBar: false,
  notificationBarMessage: '',
  notificationBarType: '',
  fetchingFile: false,
  fetchingChat: '',
  company: '',
  blockedURLS: [],
};

// OUTSIDE ACTIONS
export const signIn = createAsyncThunk('app/signin', async (user, thunkApi) => {
  let access_click2chat = JSON.stringify({
    username: user.u,
    password: user.p,
  });
  const response = await API.post.login(access_click2chat);
  if (response.status === 201) {
    let accessClick2chat = {
      token: response.data.info.token,
      expiration_date: response.data.info.expiration_date,
      url: response.data.info.url,
      // url: 'http://localhost:65535',
    };
    accessClick2chat = createAccess(accessClick2chat);

    if (response.data.info.worker === 'operator') {
      let clockPunch;
      try {
        clockPunch = await API.post.clockPunch(accessClick2chat, 'login');
      } catch (err) {
        console.log({ err });
      }
      if (clockPunch.status === 201) {
        response.data.info.clockPunch = clockPunch.data.info.currentStatus;
        response.data.operation = clockPunch.data.info.operation;
        return response.data;
      } else {
        let message;
        if (response?.data?.msg) {
          message = response.data.msg;
        } else {
          message = 'Rejected';
        }

        return thunkApi.rejectWithValue({ message });
      }
    } else if (response.data.info.worker === 'supervisor') {
      let clockPunch;
      try {
        clockPunch = await API.post.clockPunchSupervisionIn(accessClick2chat);
      } catch (err) {
        console.log({ err });
      }
      if (clockPunch.status === 201) {
        response.data.supervision = clockPunch.data.info.supervision;
        return response.data;
      } else {
        let message;
        if (response?.data?.msg) {
          message = response.data.msg;
        } else {
          message = 'Rejected';
        }

        return thunkApi.rejectWithValue({ message });
      }
    } else {
      return response.data;
    }
  } else {
    let message;
    if (response?.data?.msg) {
      message = response.data.msg;
    } else {
      message = 'Rejected';
    }

    return thunkApi.rejectWithValue({ message });
  }
});
export const signOut = createAsyncThunk(
  'app/signout',
  async (access_click2chat) => {
    await API.post.clockPunch(createAccess(access_click2chat), 'logoff');
    return 'logout';
  }
);

export const getChats = createAsyncThunk(
  'app/get-chats',
  async (access_click2chat, thunkApi) => {
    if (!access_click2chat.fetchingChats) {
      const response = await API.get.chats(createAccess(access_click2chat));
      if (response.ok) {
        return response.data;
      } else {
        let message;
        if (response?.data?.msg) {
          message = response.data.msg;
        } else {
          message = 'Rejected';
        }

        return thunkApi.rejectWithValue({ message });
      }
    }
  }
);

export const getInternalChatsCountSupervision = createAsyncThunk(
  'app/get-internal-chats-count-supervision',
  async (access_click2chat, thunkApi) => {
    if (!access_click2chat.fetchintInternalChats) {
      const response = await API.get.internalSupervisorCount(
        createAccess(access_click2chat)
      );
      if (response.ok) {
        // setToken(response.data.token);
        return response.data;
      } else {
        let message;
        if (response?.data?.msg) {
          message = response.data.msg;
        } else {
          message = 'Rejected';
        }

        return thunkApi.rejectWithValue({ message });
      }
    }
  }
);

export const getInternalChats = createAsyncThunk(
  'app/get-internal-chats',
  async (access_click2chat, thunkApi) => {
    if (!access_click2chat.fetchintInternalChats) {
      const response = await API.get.internalChats(
        createAccess(access_click2chat)
      );
      if (response.ok) {
        // setToken(response.data.token);
        return response.data;
      } else {
        let message;
        if (response?.data?.msg) {
          message = response.data.msg;
        } else {
          message = 'Rejected';
        }

        return thunkApi.rejectWithValue({ message });
      }
    }
  }
);

export const getChat = createAsyncThunk(
  'app/get-chat',
  async (data, thunkApi) => {
    let access_click2chat = createAccess(data.access_click2chat);
    const response = await API.get.chat(access_click2chat, data.chat_id);
    if (response.ok) {
      if (response.data && response.data.info && response.data.info.chat) {
        // let chat = response.data.info.chat;
        // let hasMedia = chat.filter(el => el.hasMedia);
        // let notDownloaded =  hasMedia.filter(el => !data.files.includes(el.media_id));
        // let _files = {};
        // if (notDownloaded.length > 0) {
        //   for (let i=0; i <= notDownloaded.length - 1; i++) {
        //       let file = await API.get.file(access_click2chat, notDownloaded[i].media_id);
        //       if (file.ok && file.data && file.data.data) {
        //         _files[notDownloaded[i].media_id] = {
        //           url: b64toBlob(file.data.data.file, file.data.data.mime_type),
        //           filename: file.data.data.filename
        //         };
        //       }
        //     // }
        //   }
        //   response.data.files = _files;
        // }
      }
      // console.log({ x: response.data })
      return response.data;
    } else {
      let message;
      if (response?.data?.msg) {
        message = response.data.msg;
      } else {
        message = 'Rejected';
      }

      return thunkApi.rejectWithValue({ message });
    }
  }
);

export const getChatHistory = createAsyncThunk(
  'app/get-chat-history',
  async (data, thunkApi) => {
    let access_click2chat = createAccess(data.access_click2chat);
    const response = await API.get.chatHistory(access_click2chat, data.chat_id);
    if (response.ok) {
      if (response.data && response.data.info && response.data.info.path) {
        // let chat = response.data.info.chat;
        // let hasMedia = chat.filter(el => el.hasMedia);
        // let notDownloaded =  hasMedia.filter(el => !data.files.includes(el.media_id));
        // let _files = {};
        // if (notDownloaded.length > 0) {
        //   for (let i=0; i <= notDownloaded.length - 1; i++) {
        //       let file = await API.get.file(access_click2chat, notDownloaded[i].media_id);
        //       if (file.ok && file.data && file.data.data) {
        //         _files[notDownloaded[i].media_id] = {
        //           url: b64toBlob(file.data.data.file, file.data.data.mime_type),
        //           filename: file.data.data.filename
        //         };
        //       }
        //     // }
        //   }
        //   response.data.files = _files;
        // }
      }
      return response.data;
    } else {
      let message;
      if (response?.data?.msg) {
        message = response.data.msg;
      } else {
        message = 'Rejected';
      }

      return thunkApi.rejectWithValue({ message });
    }
  }
);

export const postNote = createAsyncThunk(
  'app/post-note',
  async (data, thunkApi) => {
    const response = await API.post.note(
      createAccess(data.access_click2chat),
      data.title,
      data.text
    );
    if (response.ok) {
      // setToken(response.data.token);
      return response.data;
    } else {
      let message;
      if (response?.data?.msg) {
        message = response.data.msg;
      } else {
        message = 'Rejected';
      }

      return thunkApi.rejectWithValue({ message });
    }
  }
);

export const putNote = createAsyncThunk(
  'app/put-note',
  async (data, thunkApi) => {
    const response = await API.put.note(
      createAccess(data.access_click2chat),
      data.title,
      data.text,
      data.id
    );
    if (response.ok) {
      return response.data;
    } else {
      let message;
      if (response?.data?.msg) {
        message = response.data.msg;
      } else {
        message = 'Rejected';
      }

      return thunkApi.rejectWithValue({ message });
    }
  }
);

export const deleteNote = createAsyncThunk(
  'app/delete-note',
  async (data, thunkApi) => {
    const response = await API.delete.note(
      createAccess(data.access_click2chat),
      data.id
    );
    if (response.ok) {
      return response.data;
    } else {
      let message;
      if (response?.data?.msg) {
        message = response.data.msg;
      } else {
        message = 'Rejected';
      }

      return thunkApi.rejectWithValue({ message });
    }
  }
);

export const appSlice = createSlice({
  name: 'app',
  initialState,
  // INTERNAL ACTIONS
  reducers: {
    setCompanyURL: (state, action) => {
      state.company = action.payload;
    },
    setSelectedChat: (state, action) => {
      if (state.operation) {
        state.operation.selectedChat = {
          id: action.payload._id,
          last_interaction: action.payload,
        };
        state.operation.selectedChat.messages = [];
        state.operation.selectedChat.path = [];
      }
    },
    setSelectedChatList: (state, action) => {
      if (state.operation) {
        state.operation.selectedChatList = action.payload;
        state.operation.referenceList = '';
        state.operation.chatsLists.searching = [];
        state.operation.searchedWord = '';
        state.operation.selectedChat = null;
        state.operation.selectedChat = {
          id: state.operation.chatsLists[action.payload][0]._id,
          last_interaction: state.operation.chatsLists[action.payload][0],
        };
      }
    },
    setSearching: (state, action) => {
      if (state.operation) {
        state.operation.searching = action.payload.status;
        state.operation.searchedWord = action.payload.text;
        if (action.payload.status) {
          if (!state.operation.referenceList) {
            state.operation.referenceList = state.operation.selectedChatList;
          }
          state.operation.selectedChatList = 'searching';
          state.operation.chatsLists.searching = state.operation.chatsLists[
            state.operation.referenceList
          ].filter(
            (el) =>
              el.phone_number.includes(action.payload.text) ||
              (el.last_interaction &&
                el.last_interaction.body &&
                el.last_interaction.body
                  .toLowerCase()
                  .includes(action.payload.text)) ||
              (el.user && el.user.toLowerCase().includes(action.payload.text))
          );
        } else {
          state.operation.selectedChatList = state.operation.referenceList;
          state.operation.referenceList = '';
          state.operation.chatsLists.searching = [];
        }
      }
    },
    setNotificationBar: (state, action) => {
      if (!action.payload.status) {
        state.showNotificationBar = action.payload.status;
        state.notificationBarMessage = '';
        state.notificationBarType = '';
      } else {
        state.showNotificationBar = action.payload.status;
        state.notificationBarMessage = action.payload.message;
        state.notificationBarType = action.payload.type;
      }
    },
    signout: (state) => {
      state.user = null;
      state.fetching = false;
      state.fetchingChats = false;
      state.operation = null;
      if (state?.supervision?.filterInterval) {
        clearInterval(state.supervision['filterInterval']);
      }
      state.supervision = null;
      state.internal = null;
      state.fetchingInternalChats = false;
      state.fetchingFile = false;
      state.fetchingChat = '';
      state.blockedURLS = [];
    },
    setFetchingChat: (state, action) => {
      state.fetchingChat = action.payload;
    },
    setCloseReasonList: (state, action) => {
      state.operation.closeReasonList = action.payload;
    },
    setFilterType: (state, action) => {
      if (state?.supervision) {
        state.supervision['filterType'] = action.payload;
      }
    },
    setFilteredChatsList: (state, action) => {
      if (state?.supervision) {
        state.supervision['filteredChatsList'] = action.payload;
      }
    },
    setFilterKeyWord: (state, action) => {
      if (state?.supervision) {
        state.supervision['filterKeyWord'] = action.payload;
      }
    },
    setFilteredChat: (state, action) => {
      if (state?.supervision) {
        state.supervision['filteredChat'] = action.payload;
      }
    },
    setFilterInterval: (state, action) => {
      if (state?.supervision) {
        state.supervision['filterInterval'] = action.payload;
      }
    },
    setBlockedURLS: (state, action) => {
      if (!state?.blockedURLS.includes(action.payload)) {
        state.blockedURLS.push(action.payload);
      }
    },
  },
  // ADDING OUTSIDE ACTIONS TO SLICE
  extraReducers: (builder) => {
    builder
      .addCase(signIn.pending, (state) => {
        console.log('SIGN IN - PENDING');
        state.status = 'loading';
      })
      .addCase(signIn.fulfilled, (state, action) => {
        console.log('SIGN IN - FULFILLED');
        state.status = 'done';
        state.fetching = false;
        state.internal = {};
        state.user = action.payload.info;
        if (action.payload.info.worker === 'operator') {
          state.operation = action.payload.operation;
          state.operation['chatsLists'] = {
            all: [],
            no: [],
            new: [],
            bot: [],
            forward: [],
            schedule: [],
            star: [],
            searching: [],
            queue: [],
            whisper: [],
          };
          state.operation['selectedChatList'] = 'all';
          state.operation['searching'] = false;
          state.operation['referenceList'] = '';
          state.operation['searchedWord'] = '';
          state.operation['files'] = {};
        } else if (action.payload.info.worker === 'supervisor') {
          state.supervision = action.payload.supervision;
          state.supervision['filteredChatsList'] = [];
          state.supervision['filterType'] = '';
          state.supervision['filterKeyWord'] = '';
        }
      })
      .addCase(signIn.rejected, (state) => {
        console.log('SIGN IN - REJECTED');
        state.fetching = false;
        state.showNotificationBar = true;
        state.notificationBarMessage = 'Não autorizado';
        state.notificationBarType = 'warning';
      })
      .addCase(signOut.pending, (state) => {
        console.log('SIGN OUT - PENDING');
        state.fetching = true;
      })
      .addCase(signOut.fulfilled, (state, action) => {
        console.log('SIGN OUT - FULFILLED');
        state.user = null;
        state.fetching = false;
        state.fetchingChats = false;
        state.operation = null;
        if (state?.supervision?.filterInterval) {
          clearInterval(state.supervision['filterInterval']);
        }
        state.supervision = null;
        state.internal = null;
        state.fetchingInternalChats = false;
        state.fetchingFile = false;
        state.fetchingChat = '';
        state.blockedURLS = [];
      })
      .addCase(signOut.rejected, (state) => {
        console.log('SIGN OUT - REJECTED');
        state.fetching = false;
        state.showNotificationBar = true;
        state.notificationBarMessage = 'Não autorizado';
        state.notificationBarType = 'warning';
      })
      .addCase(getChats.pending, (state) => {
        // console.log("GET CHATS - PENDING");
        state.fetching = true;
        state.fetchingChats = true;
      })
      .addCase(getChats.fulfilled, (state, action) => {
        if (action.payload.info.hash !== state?.operation?.chatsHash) {
          console.log('GET CHATS - FULFILLED');
          state.operation.chatsHash = action.payload.info.hash;
          if (
            state.operation &&
            state.operation['chatsLists'] &&
            action?.payload?.info?.chats
          ) {
            let allChats = action.payload.info.chats;
            state.operation['chatsLists']['all'] = allChats;
            state.operation['chatsLists']['no'] = allChats.filter(
              (el) => el.new_interaction === 'no'
            );
            state.operation['chatsLists']['new'] = allChats.filter(
              (el) => el.new_interaction === 'yes'
            );
            state.operation['chatsLists']['whisper'] = allChats.filter(
              (el) => el.new_interaction === 'whisper'
            );
            state.operation['chatsLists']['bot'] = allChats.filter((el) =>
              ['robot', 'massive', 'external'].includes(el.new_interaction)
            );
            state.operation['chatsLists']['queue'] = allChats.filter((el) =>
              ['queue'].includes(el.new_interaction)
            );
            state.operation['chatsLists']['forward'] = allChats.filter((el) =>
              ['forwarded'].includes(el.new_interaction)
            );
            state.operation['chatsLists']['schedule'] = allChats.filter((el) =>
              ['scheduled'].includes(el.new_interaction)
            );
            state.operation['chatsLists']['star'] = allChats.filter(
              (el) => el.starred
            );

            if (
              state.operation &&
              state.operation.selectedChat &&
              state.operation.selectedChat.id
            ) {
              let currentLastTime =
                state.operation.selectedChat.last_interaction_time;
              let ID = state.operation.selectedChat.id;
              let setupNewInteraction = allChats.filter(
                (el) =>
                  el._id === ID && el.last_interaction_time !== currentLastTime
              );
              if (setupNewInteraction.length > 0) {
                state.operation.selectedChat.last_interaction =
                  setupNewInteraction[0];
              }
            }
            if (
              state.operation &&
              state.operation.selectedChat &&
              state.operation.selectedChat.id
            ) {
              let ID = state.operation.selectedChat.id;
              let setupNewInteraction = allChats.filter((el) => el._id === ID);
              if (setupNewInteraction.length === 0) {
                state.operation.selectedChat = null;
              }
            }
          } else if (state.operation && state.operation['chatsLists']) {
            let statusPause = action?.payload?.info?.otherInfo?.paused ?? false;
            state.operation['chatsLists']['all'] = [];
            state.operation['chatsLists']['no'] = [];
            state.operation['chatsLists']['new'] = [];
            state.operation['chatsLists']['bot'] = [];
            state.operation['chatsLists']['forward'] = [];
            state.operation['chatsLists']['schedule'] = [];
            state.operation['chatsLists']['star'] = [];
            state.operation['chatsLists']['searching'] = [];
            state.operation['statusPause'] = statusPause;

            if (
              state.operation &&
              state.operation.selectedChat &&
              state.operation.selectedChat.id
            ) {
              state.operation.selectedChat = null;
            }
          }
        }
        state.fetchingChats = false;
        state.fetching = false;
        let statusPause = action?.payload?.info?.otherInfo?.paused ?? false;
        state.operation['statusPause'] = statusPause;
        if (action?.payload?.info?.otherInfo?.internal) {
          state.internal.newChatsCounting =
            action?.payload?.info?.otherInfo?.internal?.newChats ?? 0;
        }
      })
      .addCase(getChats.rejected, (state) => {
        console.log('GET CHATS - REJECTED');
        state.fetching = false;
        state.fetchingChats = false;
      })
      .addCase(getInternalChats.pending, (state) => {
        console.log('GET INTERNAL CHATS - PENDING');
        state.fetching = true;
        state.fetchingInternalChats = true;
      })
      .addCase(getInternalChats.fulfilled, (state, action) => {
        if (state.internal) {
          let allChats = action.payload.data.chats;
          state.internal['chats'] = allChats;
          console.log('GET INTERNAL CHATS - FULFILLED');
        }
        state.fetchingInternalChats = false;
        state.fetching = false;
      })
      .addCase(getInternalChats.rejected, (state) => {
        console.log('GET INTERNAL CHATS - REJECTED');
        state.fetching = false;
        state.fetchingInternalChats = false;
      })
      .addCase(getInternalChatsCountSupervision.pending, (state) => {
        console.log('GET INTERNAL COUNT SUPERVISION - PENDING');
      })
      .addCase(getInternalChatsCountSupervision.fulfilled, (state, action) => {
        if (state.internal) {
          if (action?.payload?.info?.internal) {
            state.internal.newChatsCounting =
              action?.payload?.info?.internal?.newChats ?? 0;
          }
          console.log('GET INTERNAL COUNT SUPERVISION - FULFILLED');
        }
      })
      .addCase(getInternalChatsCountSupervision.rejected, (state) => {
        console.log('GET INTERNAL CHATS COUNT SUPERVISION - REJECTED');
      })
      .addCase(getChat.pending, (state) => {
        console.log('GET CHAT - PENDING');
        state.fetching = true;
      })
      .addCase(getChat.fulfilled, (state, action) => {
        console.log('GET CHAT - FULFILLED');
        state.fetching = false;
        if (state.operation && state.operation.selectedChat) {
          state.operation.selectedChat.messages =
            action.payload.info && action.payload.info.chat
              ? action.payload.info.chat
              : [];
          state.operation.selectedChat.profile_pic_url =
            action.payload.info && action.payload.info.profile_pic_url
              ? action.payload.info.profile_pic_url
              : '';
          state.operation.selectedChat.vmTag =
            action.payload.info && action.payload.info.vmTag
              ? action.payload.info.vmTag
              : '';
          // state.operation.selectedChat.path = action.payload.info && action.payload.info.path ? action.payload.info.path : [];
          if (action.payload.files) {
            let files = action.payload.files;
            state.operation.files = Object.assign(state.operation.files, files);
          }
          if (state.fetchingChat) {
            state.fetchingChat = '';
          }
        }
      })
      .addCase(getChat.rejected, (state) => {
        console.log('GET CHAT - REJECTED');
        state.fetching = false;
      })
      .addCase(getChatHistory.pending, (state) => {
        console.log('GET CHAT HISTORY - PENDING');
        state.fetching = true;
      })
      .addCase(getChatHistory.fulfilled, (state, action) => {
        console.log('GET CHAT HISTORY - FULFILLED');
        state.fetching = false;
        if (state?.operation?.selectedChat) {
          state.operation.selectedChat.path = action?.payload?.info?.path ?? [];
        }
      })
      .addCase(getChatHistory.rejected, (state) => {
        console.log('GET CHAT HISTORY - REJECTED');
        state.fetching = false;
      })
      .addCase(postNote.pending, (state) => {
        console.log('POST NOTE - PENDING');
        state.fetching = true;
      })
      .addCase(postNote.fulfilled, (state, action) => {
        console.log('POST NOTE - FULFILLED');
        state.fetching = false;
        console.log({ action });
        state.operation.notes = action.payload.info.notes;
      })
      .addCase(postNote.rejected, (state) => {
        console.log('POST NOTE - REJECTED');
        state.fetching = false;
      })
      .addCase(putNote.pending, (state) => {
        console.log('PUT NOTE - PENDING');
        state.fetching = true;
      })
      .addCase(putNote.fulfilled, (state, action) => {
        console.log('PUT NOTE - FULFILLED');
        state.fetching = false;
        state.operation.notes = action.payload.info.notes;
      })
      .addCase(putNote.rejected, (state) => {
        console.log('PUT NOTE - REJECTED');
        state.fetching = false;
      })
      .addCase(deleteNote.pending, (state) => {
        console.log('DELETE NOTE - PENDING');
        state.fetching = true;
      })
      .addCase(deleteNote.fulfilled, (state, action) => {
        console.log('DELETE NOTE - FULFILLED');
        state.fetching = false;
        state.operation.notes = action.payload.info.notes;
      })
      .addCase(deleteNote.rejected, (state) => {
        console.log('DELETE NOTE - REJECTED');
        state.fetching = false;
      });
  },
});

// EXPOSING INTERNAL ACTIONS
export const {
  setSelectedChatList,
  setSelectedChat,
  setSearching,
  signout,
  setNotificationBar,
  setFetchingChat,
  setCloseReasonList,
  setCompanyURL,
  setFilterType,
  setFilteredChatsList,
  setFilterKeyWord,
  setFilteredChat,
  setFilterInterval,
  setBlockedURLS,
} = appSlice.actions;

// EXPOSING VARIABLES
export const appUser = (state) => state.app.user;
export const companyURL = (state) => state.app.company;
export const appOperation = (state) => state.app.operation || {};
export const appSupervision = (state) => state.app.supervision || {};
export const appShowNotificationBar = (state) => state.app.showNotificationBar;
export const appNotificationBarMessage = (state) =>
  state.app.notificationBarMessage;
export const appNotificationBarType = (state) => state.app.notificationBarType;
export const appFetching = (state) => state.app.fetching;
export const appFetchingChats = (state) => state.app.fetchingChats;
export const appFetchingInternalChats = (state) =>
  state.app.fetchingInternalChats;
export const appFetchingChat = (state) => state.app.fetchingChat;
export const appInternal = (state) => state.app.internal;
export const appBlockedURLS = (state) => state.app.blockedURLS;

export default appSlice.reducer;
