// React
import React, { useState, useEffect } from 'react';

// Design
import { makeStyles } from '@mui/styles';
import AppBar from '@mui/material/AppBar';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Toolbar from '@mui/material/Toolbar';
import { Box, CircularProgress, Grid } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DownloadIcon from '@mui/icons-material/Download';
import UploadIcon from '@mui/icons-material/Upload';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import PhoneForwardedIcon from '@mui/icons-material/PhoneForwarded';
import FiberNewIcon from '@mui/icons-material/FiberNew';
import HistoryToggleOffIcon from '@mui/icons-material/HistoryToggleOff';
import PauseIcon from '@mui/icons-material/Pause';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ThumbsUpDownIcon from '@mui/icons-material/ThumbsUpDown';
import RuleIcon from '@mui/icons-material/Rule';
import Tooltip from '@mui/material/Tooltip';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import BlockIcon from '@mui/icons-material/Block';
import Headset from '@mui/icons-material/Headset';
import BackupTableIcon from '@mui/icons-material/BackupTable';
import EditNotificationsIcon from '@mui/icons-material/EditNotifications';

// Internal
import { appUser } from '../../store/app/slice';
import createAccess from '../../utils/functions/createAccess';
import API from '../../utils/api';
import b64toBlob from '../../utils/functions/b64toBlob';
import openURI from '../../utils/functions/openURI';
import downloadURI from '../../utils/functions/downloadURI';

// Third-Party
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  appBar: {
    // position: 'relative',
    // marginBottom: '5rem'
    overflow: 'hidden',
    '@media print': {
      display: 'block !important',
      width: 'auto !important',
      height: 'auto !important',
      overflow: 'visible !important',
      marginLeft: '0 !important',
      // 'pageBreakAfter': 'always !important',
    },
  },
  title: {
    // marginLeft: theme.spacing(2),
    flex: 1,
  },
  path: {
    marginTop: '4.5rem',
    width: '100%',
    height: '94vh',
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
    '@media print': {
      display: 'block !important',
      width: 'auto !important',
      height: 'auto !important',
      overflow: 'visible !important',
      marginLeft: '0 !important',
      pageBreakAfter: 'avoid !important',
    },
  },
  buttons: {
    '@media print': {
      display: 'hide !important',
    },
  },
  childrenPath: {
    display: 'flex',
    width: '100%',
    // height: '50rem',
    alignItems: 'center',
    border: '0.09rem solid black',
    borderRadius: '1%',
    marginBottom: '0.5rem',
    '@media print': {
      display: 'block !important',
      width: 'auto !important',
      height: 'auto !important',
      overflow: 'visible !important',
      marginLeft: '0 !important',
      // 'pageBreakAfter': 'always !important',
    },
  },
}));
const options = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
  hour12: false,
  timeZone: 'America/Sao_Paulo',
};

//  {"action":"whisper sent","timestamp":"2023-09-16T16:34:43.422Z","info":{"description":"Whisper sender: felipe. Current Operator: felipe.operator.","msg":"oi"},"channel":"whatsapp"}
const layoutFunctions = {
  'notification sent': (el, index, classes, user) => {
    let key = `${el.action}-${index}`;
    let description = el?.info?.description
      ?.replace(/supervisor/gi, 'Supervisor ')
      ?.replace(
        /sent a notification to the user/,
        ' enviou uma notificação para o usuário'
      );

    return (
      <Box className={classes.childrenPath} key={key}>
        <Grid container justifyContent="space-evenly">
          <Grid item xs={2}>
            <EditNotificationsIcon sx={{ fontSize: '5rem' }} color="info" />
          </Grid>
          <Grid item xs={4}>
            <Box
              sx={{
                whiteSpace: 'wrap',
              }}
            >
              {description}
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box
              sx={{
                whiteSpace: 'wrap',
              }}
            >
              {el?.info?.message ?? el?.message}
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box
              sx={{
                whiteSpace: 'wrap',
              }}
            >
              <Typography variant="caption">
                Horário:{' '}
                {Intl.DateTimeFormat('pt-BR', options).format(
                  new Date(el.timestamp)
                )}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    );
  },
  'whisper sent': (el, index, classes, user) => {
    let key = `${el.action}-${index}`;
    let description = el?.info?.description
      ?.replace(/whisper sender:/gi, 'Quem enviou o susurro: ')
      .replace(/current operator:/gi, 'Com quem estava quando foi enviada:')
      .replace(/supervisor/gi, 'Supervisor')
      .replace(/operator/gi, 'Operador')
      .replace(/from/gi, 'De')
      .replace(/to:/gi, 'Para:');

    return (
      <Box className={classes.childrenPath} key={key}>
        <Grid container justifyContent="space-evenly">
          <Grid item xs={2}>
            <Headset sx={{ fontSize: '5rem' }} color="info" />
          </Grid>
          <Grid item xs={4}>
            <Box
              sx={{
                whiteSpace: 'wrap',
              }}
            >
              {description}
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box
              sx={{
                whiteSpace: 'wrap',
              }}
            >
              {el?.info?.msg}
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box
              sx={{
                whiteSpace: 'wrap',
              }}
            >
              <Typography variant="caption">
                Horário:{' '}
                {Intl.DateTimeFormat('pt-BR', options).format(
                  new Date(el.timestamp)
                )}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    );
  },
  'chat blocked': (el, index, classes, user) => {
    let key = `${el.action}-${index}`;
    let description = el?.info?.description
      ?.replace(/supervisor/gi, 'Supervisor')
      .replace(/worker/gi, 'Hierarquia')
      .replace(/operator/gi, 'Operador')
      .replace(/doer type/gi, 'Hierarquia')
      .replace(/reason/gi, 'Tabulação')
      .replace(/from/gi, 'Usuário')
      .replace(/user/gi, 'Usuário')
      .replace(/true/gi, 'Bloqueado')
      .replace(/false/gi, 'Desbloqueado');

    return (
      <Box className={classes.childrenPath} key={key}>
        <Grid container justifyContent="flex-start">
          <Grid item xs={2}>
            <BlockIcon
              sx={{ fontSize: '5rem' }}
              color={el.info.description.includes('true') ? 'error' : 'success'}
            />
          </Grid>
          <Grid item xs={8}>
            <Box
              sx={{
                whiteSpace: 'wrap',
              }}
            >
              {description}
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box
              sx={{
                whiteSpace: 'wrap',
              }}
            >
              <Typography variant="caption">
                Horário:{' '}
                {Intl.DateTimeFormat('pt-BR', options).format(
                  new Date(el?.timestamp)
                )}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    );
  },
  'chat closed': (el, index, classes, user) => {
    let key = `${el.action}-${index}`;
    let description = el?.info?.description
      ?.replace(/supervisor/gi, 'Supervisor.')
      .replace(/worker/gi, 'Hierarquia')
      .replace(/operator/gi, 'Operador')
      .replace(/closer/gi, 'Quem encerrou')
      .replace(/reason/gi, 'Tabulação');

    return (
      <Box className={classes.childrenPath} key={key}>
        <Grid container justifyContent="flex-start">
          <Grid item xs={2}>
            <CloseIcon sx={{ fontSize: '5rem' }} color="error" />
          </Grid>
          <Grid item xs={8}>
            <Box
              sx={{
                whiteSpace: 'wrap',
              }}
            >
              {description}
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box
              sx={{
                whiteSpace: 'wrap',
              }}
            >
              <Typography variant="caption">
                Horário:{' '}
                {Intl.DateTimeFormat('pt-BR', options).format(
                  new Date(el?.timestamp)
                )}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    );
  },
  'message sent': (el, index, classes, user) => {
    let key = `${el.action}-${index}`;
    let description = el?.info?.description
      ?.replace(/message sender:/gi, '')
      .replace(/robot/gi, 'Robô')
      .replace(/system/gi, 'Sistema');
    let message = el?.info?.message?.filename ?? el?.info?.message;
    // eslint-disable-next-line
    let media = el.info.media;
    const handleLazyOpen = async (media_id) => {
      let access_click2chat = createAccess(user);
      let file = await API.get.file(access_click2chat, media_id);
      if (file.ok && file.data && file.data.data) {
        let url = b64toBlob(file.data.data.file, file.data.data.mime_type);
        openURI(url);
      }
    };
    const handleLazyDownload = async (media_id) => {
      let access_click2chat = createAccess(user);
      let file = await API.get.file(access_click2chat, media_id);
      if (file.ok && file.data && file.data.data) {
        let url = b64toBlob(file.data.data.file, file.data.data.mime_type);
        let filename = file.data.data.filename;
        downloadURI(url, filename);
      }
    };

    return (
      <Box className={classes.childrenPath} key={key}>
        <Grid container justifyContent="flex-start">
          <Grid item xs={2}>
            <UploadIcon sx={{ fontSize: '5rem' }} color="primary" />
          </Grid>
          <Grid item xs={4}>
            <Box
              sx={{
                whiteSpace: 'wrap',
              }}
            >
              {description}
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box
              sx={{
                whiteSpace: 'wrap',
              }}
            >
              {message ?? media}
            </Box>
            {media && (
              <Typography>
                <Tooltip title={`Baixar Arquivo}`}>
                  <IconButton onClick={() => handleLazyDownload(media)}>
                    <DownloadIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title={`Abrir Arquivo}`}>
                  <IconButton onClick={() => handleLazyOpen(media)}>
                    <OpenInNewIcon />
                  </IconButton>
                </Tooltip>
              </Typography>
            )}
          </Grid>
          <Grid item xs={2}>
            <Box>
              <Typography variant="caption">
                Horário:{' '}
                {Intl.DateTimeFormat('pt-BR', options).format(
                  new Date(el?.timestamp)
                )}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    );
  },
  'message received': (el, index, classes, user) => {
    let key = `${el.action}-${index}`;
    let description = el?.info?.description
      ?.replace(/message sender:/gi, '')
      .replace(/user/gi, 'Cliente');
    let message = el?.info?.message?.filename ?? el?.info?.message;
    // eslint-disable-next-line
    let media = el.info.media;
    const handleLazyOpen = async (media_id) => {
      let access_click2chat = createAccess(user);
      let file = await API.get.file(access_click2chat, media_id);
      if (file.ok && file.data && file.data.data) {
        let url = b64toBlob(file.data.data.file, file.data.data.mime_type);
        openURI(url);
      }
    };
    const handleLazyDownload = async (media_id) => {
      let access_click2chat = createAccess(user);
      let file = await API.get.file(access_click2chat, media_id);
      if (file.ok && file.data && file.data.data) {
        let url = b64toBlob(file.data.data.file, file.data.data.mime_type);
        let filename = file.data.data.filename;
        downloadURI(url, filename);
      }
    };
    return (
      <Box className={classes.childrenPath} key={key}>
        <Grid container justifyContent="flex-start">
          <Grid item xs={2}>
            <DownloadIcon sx={{ fontSize: '5rem' }} color="secondary" />
          </Grid>
          <Grid item xs={4}>
            <Box
              sx={{
                whiteSpace: 'wrap',
              }}
            >
              {description}
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box
              sx={{
                whiteSpace: 'wrap',
              }}
            >
              {message === '' && media ? 'ARQUIVO' : message}
            </Box>
            {media && (
              <Typography>
                <Tooltip title={`Baixar Arquivo}`}>
                  <IconButton onClick={() => handleLazyDownload(media)}>
                    <DownloadIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title={`Abrir Arquivo}`}>
                  <IconButton onClick={() => handleLazyOpen(media)}>
                    <OpenInNewIcon />
                  </IconButton>
                </Tooltip>
              </Typography>
            )}
          </Grid>
          <Grid item xs={2}>
            <Box>
              <Typography variant="caption">
                Horário:{' '}
                {Intl.DateTimeFormat('pt-BR', options).format(
                  new Date(el?.timestamp)
                )}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    );
  },
  'chat forwarded': (el, index, classes, user) => {
    let key = `${el.action}-${index}`;
    let description = el?.info?.description
      ?.replace(/doer type/gi, 'Quem Fez')
      .replace(/current operator/gi, 'Com quem estava quando foi encerrada:')
      .replace(/supervisor/gi, 'Supervisor')
      .replace(/operator/gi, 'Operador')
      .replace(/from/gi, 'De')
      .replace(/to:/gi, 'Para:')
      .replace(
        /transfered msg de bot to human/gi,
        'Conversa transferida do robô para operador.'
      );

    return (
      <Box className={classes.childrenPath} key={key}>
        <Grid container justifyContent="space-evenly">
          <Grid item xs={2}>
            <CompareArrowsIcon sx={{ fontSize: '5rem' }} color="success" />
          </Grid>
          <Grid item xs={8}>
            <Box
              sx={{
                whiteSpace: 'wrap',
              }}
            >
              {description}
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box>
              <Typography variant="caption">
                Horário:{' '}
                {Intl.DateTimeFormat('pt-BR', options).format(
                  new Date(el?.timestamp)
                )}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    );
  },
  'chat forwarded to supervisor': (el, index, classes, user) => {
    let key = `${el.action}-${index}`;
    let description = el?.info?.description
      ?.replace(/doer type/gi, 'Quem Fez')
      .replace(/current operator/gi, 'Com quem estava quando foi encerrada:')
      .replace(/supervisor/gi, 'Supervisor')
      .replace(/operator/gi, 'Operador')
      .replace(/from/gi, 'De')
      .replace(/to:/gi, 'Para:')
      .replace(
        /transfered msg de bot to human/gi,
        'Conversa transferida do robô para operador.'
      );

    return (
      <Box className={classes.childrenPath} key={key}>
        <Grid container justifyContent="space-evenly">
          <Grid item xs={2}>
            <RuleIcon sx={{ fontSize: '5rem' }} color="error" />
          </Grid>
          <Grid item xs={8}>
            <Box
              sx={{
                whiteSpace: 'wrap',
              }}
            >
              {description}
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box
              sx={{
                whiteSpace: 'wrap',
              }}
            >
              <Typography variant="caption">
                Horário:{' '}
                {Intl.DateTimeFormat('pt-BR', options).format(
                  new Date(el?.timestamp)
                )}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    );
  },
  'new responsible': (el, index, classes, user) => {
    let key = `${el.action}-${index}`;
    let description = el?.info?.description?.replace(
      /operator id:/gi,
      'O chat entrou para:'
    );
    return (
      <Box className={classes.childrenPath} key={key}>
        <Grid container justifyContent="space-evenly">
          <Grid item xs={2}>
            <NewReleasesIcon sx={{ fontSize: '5rem' }} color="warning" />
          </Grid>
          <Grid item xs={8}>
            <Box
              sx={{
                whiteSpace: 'wrap',
              }}
            >
              {description}
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box
              sx={{
                whiteSpace: 'wrap',
              }}
            >
              <Typography variant="caption">
                Horário:{' '}
                {Intl.DateTimeFormat('pt-BR', options).format(
                  new Date(el?.timestamp)
                )}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    );
  },
  'changed responsible vm': (el, index, classes, user) => {
    let key = `${el.action}-${index}`;
    let description = el?.info?.description?.replace(
      /changing vm/gi,
      'A VM responsável por enviar e receber foi alterada.'
    );
    return (
      <Box className={classes.childrenPath} key={key}>
        <Grid container justifyContent="space-evenly">
          <Grid item xs={2}>
            <PhoneForwardedIcon sx={{ fontSize: '5rem' }} />
          </Grid>
          <Grid item xs={8}>
            <Box
              sx={{
                whiteSpace: 'wrap',
              }}
            >
              {description}
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box
              sx={{
                whiteSpace: 'wrap',
              }}
            >
              <Typography variant="caption">
                Horário:{' '}
                {Intl.DateTimeFormat('pt-BR', options).format(
                  new Date(el?.timestamp)
                )}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    );
  },
  creation: (el, index, classes, user) => {
    let key = `${el.action}-${index}`;
    let description = el?.info?.description
      ?.replace(/chat creator: system/gi, 'Chat criado ativamente.')
      .replace(/chat creator: user/gi, 'Chat criado receptivamente.');
    return (
      <Box className={classes.childrenPath} key={key}>
        <Grid container justifyContent="space-evenly">
          <Grid item xs={2}>
            <FiberNewIcon sx={{ fontSize: '5rem' }} color="disabled" />
          </Grid>
          <Grid item xs={8}>
            <Box
              sx={{
                whiteSpace: 'wrap',
              }}
            >
              {description}
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box
              sx={{
                whiteSpace: 'wrap',
              }}
            >
              <Typography variant="caption">
                Horário:{' '}
                {Intl.DateTimeFormat('pt-BR', options).format(
                  new Date(el?.timestamp)
                )}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    );
  },
  history: (el, index, classes, user) => {
    let key = `${el.action}-${index}`;

    return (
      <Box className={classes.childrenPath} key={key}>
        <Grid container justifyContent="space-evenly">
          <Grid item xs={2}>
            <HistoryToggleOffIcon sx={{ fontSize: '5rem' }} color="success" />
          </Grid>
          <Grid item xs={8}>
            <Box
              sx={{
                whiteSpace: 'wrap',
              }}
            >
              Salvando o histórico.
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box
              sx={{
                whiteSpace: 'wrap',
              }}
            >
              <Typography variant="caption">
                Horário:{' '}
                {Intl.DateTimeFormat('pt-BR', options).format(
                  new Date(el?.timestamp)
                )}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    );
  },
  hold: (el, index, classes, user) => {
    let key = `${el.action}-${index}`;

    return (
      <Box className={classes.childrenPath} key={key}>
        <Grid container justifyContent="space-evenly">
          <Grid item xs={2}>
            <PauseIcon sx={{ fontSize: '5rem' }} color="info" />
          </Grid>
          <Grid item xs={8}>
            <Box
              sx={{
                whiteSpace: 'wrap',
              }}
            >
              Entrou para fila de espera.
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box
              sx={{
                whiteSpace: 'wrap',
              }}
            >
              <Typography variant="caption">
                Horário:{' '}
                {Intl.DateTimeFormat('pt-BR', options).format(
                  new Date(el?.timestamp)
                )}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    );
  },
  'new responsible - from hold': (el, index, classes, user) => {
    let key = `${el.action}-${index}`;
    let description = el?.info?.description?.replace(/operator id: /gi, '');
    return (
      <Box className={classes.childrenPath} key={key}>
        <Grid container justifyContent="space-evenly">
          <Grid item xs={2}>
            <PauseIcon sx={{ fontSize: '5rem' }} color="warning" />
          </Grid>
          <Grid item xs={2}>
            <PauseIcon sx={{ fontSize: '5rem' }} color="warning" />
          </Grid>
          <Grid item xs={6}>
            <Box
              sx={{
                whiteSpace: 'wrap',
              }}
            >
              Novo operador: {description}
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box
              sx={{
                whiteSpace: 'wrap',
              }}
            >
              <Typography variant="caption">
                Horário:{' '}
                {Intl.DateTimeFormat('pt-BR', options).format(
                  new Date(el?.timestamp)
                )}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    );
  },
  'massive sent': (el, index, classes, user) => {
    let key = `${el.action}-${index}`;
    // let description =  el?.info?.description?.replace(/massive interaction/gi, `Mensagem Massiva. Campanha: ${el?.info?.campaign}`)
    let description = el?.info?.description?.replace(
      /massive interaction/gi,
      `Mensagem Massiva`
    );
    let message = el?.info?.message;

    return (
      <Box className={classes.childrenPath} key={key}>
        <Grid container justifyContent="flex-start">
          <Grid item xs={2}>
            <LocalShippingIcon sx={{ fontSize: '5rem' }} color="secondary" />
          </Grid>
          <Grid item xs={4}>
            <Box
              sx={{
                whiteSpace: 'wrap',
              }}
            >
              {description}
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box
              sx={{
                whiteSpace: 'wrap',
              }}
            >
              {message}
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box>
              <Typography variant="caption">
                Horário:{' '}
                {Intl.DateTimeFormat('pt-BR', options).format(
                  new Date(el?.timestamp)
                )}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    );
  },
};

export default function PathDialog({ open, setOpen, path, phoneNumber }) {
  const classes = useStyles();
  const [reverseList, setReverseList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [reverse, setReverse] = useState(false);
  const user = useSelector(appUser);
  const handleClose = () => {
    setOpen(false);
  };

  const handlePrint = (ph) => {
    document.title = ph;
    window.print();
    document.title = 'Click2Chat';
  };

  const downloadCSV = () => {
    let csv = 'data:text/csv;charset=utf-8,';
    csv += 'Ação,Descrição,Horário\n';
    path.forEach((el) => {
      let action = el.action;
      // translate the actions
      if (el.action === 'message sent') {
        action = 'Mensagem Enviada';
      } else if (el.action === 'message received') {
        action = 'Mensagem Recebida';
      } else if (el.action === 'chat forwarded') {
        action = 'Chat Encaminhado';
      } else if (el.action === 'chat forwarded to supervisor') {
        action = 'Chat Encaminhado para Supervisor';
      } else if (el.action === 'new responsible') {
        action = 'Novo Responsável';
      } else if (el.action === 'changed responsible vm') {
        action = 'VM Alterada';
      } else if (el.action === 'creation') {
        action = 'Criação';
      } else if (el.action === 'history') {
        action = 'Histórico';
      } else if (el.action === 'hold') {
        action = 'Em Espera';
      } else if (el.action === 'new responsible - from hold') {
        action = 'Novo Responsável - De Espera';
      } else if (el.action === 'massive sent') {
        action = 'Mensagem Massiva';
      } else if (el.action === 'whisper sent') {
        action = 'Susurro Enviado';
      } else if (el.action === 'chat blocked') {
        action = 'Chat Bloqueado';
      } else if (el.action === 'chat closed') {
        action = 'Chat Encerrado';
      }
      let description = el.info.description
        ?.replace(/whisper sender:/gi, 'Quem enviou o susurro: ')
        .replace(/current operator:/gi, 'Com quem estava quando foi enviada:')
        .replace(/supervisor/gi, 'Supervisor')
        .replace(/operator/gi, 'Operador')
        .replace(/from/gi, 'De')
        .replace(/to:/gi, 'Para:')
        ?.replace(/supervisor/gi, 'Supervisor')
        .replace(/worker/gi, 'Hierarquia')
        .replace(/operator/gi, 'Operador')
        .replace(/doer type/gi, 'Hierarquia')
        .replace(/reason/gi, 'Tabulação')
        .replace(/from/gi, 'Usuário')
        .replace(/user/gi, 'Usuário')
        .replace(/true/gi, 'Bloqueado')
        .replace(/false/gi, 'Desbloqueado')
        ?.replace(/supervisor/gi, 'Supervisor.')
        .replace(/worker/gi, 'Hierarquia')
        .replace(/operator/gi, 'Operador')
        .replace(/closer/gi, 'Quem encerrou')
        .replace(/reason/gi, 'Tabulação')
        ?.replace(/message sender:/gi, '')
        .replace(/robot/gi, 'Robô')
        .replace(/system/gi, 'Sistema')
        ?.replace(/message sender:/gi, '')
        .replace(/user/gi, 'Cliente')
        ?.replace(/doer type/gi, 'Quem Fez')
        .replace(/current operator/gi, 'Com quem estava quando foi encerrada:')
        .replace(/supervisor/gi, 'Supervisor')
        .replace(/operator/gi, 'Operador')
        .replace(/from/gi, 'De')
        .replace(/to:/gi, 'Para:')
        .replace(
          /transfered msg de bot to human/gi,
          'Conversa transferida do robô para operador.'
        )
        ?.replace(/doer type/gi, 'Quem Fez')
        .replace(/current operator/gi, 'Com quem estava quando foi encerrada:')
        .replace(/supervisor/gi, 'Supervisor')
        .replace(/operator/gi, 'Operador')
        .replace(/from/gi, 'De')
        .replace(/to:/gi, 'Para:')
        .replace(
          /transfered msg de bot to human/gi,
          'Conversa transferida do robô para operador.'
        )
        .replace(/operator id:/gi, 'O chat entrou para:')
        .replace(
          /changing vm/gi,
          'A VM responsável por enviar e receber foi alterada.'
        )
        ?.replace(/chat creator: system/gi, 'Chat criado ativamente.')
        .replace(/chat creator: user/gi, 'Chat criado receptivamente.')
        .replace(/operator id: /gi, '')
        .replace(/massive interaction/gi, `Mensagem Massiva`);
      csv += `${action},${description ?? ''},${Intl.DateTimeFormat(
        'pt-BR',
        options
      ).format(new Date(el.timestamp))}\n`;
    });
    let encodedUri = encodeURI(csv);
    let link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', `${phoneNumber}.csv`);
    document.body.appendChild(link);
    link.click();
  };

  const setLayout = (_path, _reverse) => {
    let pathLayout = [];
    if (_reverse) {
      pathLayout = _path.reverse().map((el, index) => {
        try {
          let action = el.action !== 'Transfer' ? el.action : 'chat forwarded';
          return layoutFunctions[action](el, index, classes, user);
        } catch (err) {
          return (
            <Box key={index}>
              Faltando desenho para ação: {el.action} {JSON.stringify(el)}
            </Box>
          );
        }
      });
    } else {
      pathLayout = _path.map((el, index) => {
        try {
          let action = el.action !== 'Transfer' ? el.action : 'chat forwarded';
          return layoutFunctions[action](el, index, classes, user);
        } catch (err) {
          return (
            <Box key={index}>
              Faltando desenho para ação: {el.action} {JSON.stringify(el)}
            </Box>
          );
        }
      });
    }
    setReverseList(pathLayout);
    setLoading(false);
  };

  useEffect(() => {
    if (open) {
      setLayout(path, false);
    } else {
      setReverseList([], false);
    }
    // eslint-disable-next-line
  }, [open, path]);

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      fullScreen
      open={open}
      className={classes.appBar}
    >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <Typography variant="h6" className={classes.title}>
            Jornada do Cliente: {phoneNumber}
          </Typography>
          {!reverse
            ? user?.worker === 'supervisor' && (
                <IconButton
                  edge="end"
                  color="inherit"
                  onClick={() => setLayout(path, true)}
                  aria-label="close"
                  className={classes.buttons}
                >
                  <ThumbsUpDownIcon />
                </IconButton>
              )
            : user?.worker === 'supervisor' && (
                <IconButton
                  edge="end"
                  color="inherit"
                  onClick={() => setReverse(path, false)}
                  aria-label="close"
                  className={classes.buttons}
                >
                  <ThumbsUpDownIcon />
                </IconButton>
              )}
          {user?.worker === 'supervisor' && (
            <>
              <IconButton
                edge="end"
                color="inherit"
                onClick={() => handlePrint(phoneNumber)}
                aria-label="close"
                className={classes.buttons}
              >
                <PictureAsPdfIcon />
              </IconButton>
              <IconButton
                edge="end"
                color="inherit"
                onClick={() => downloadCSV()}
                aria-label="close"
                className={classes.buttons}
              >
                <BackupTableIcon />
              </IconButton>
            </>
          )}

          <IconButton
            edge="end"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
            className={classes.buttons}
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Box className={classes.path} id="pathToPrint">
        {loading ? (
          <Box
            sx={{
              display: 'block',
              width: '100%',
              height: '100%',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <CircularProgress
              sx={{
                fontSize: '5rem',
              }}
              size={120}
            />
          </Box>
        ) : (
          reverseList
        )}
      </Box>
    </Dialog>
  );
}
