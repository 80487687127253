// React
import React, { useEffect, useState } from 'react';

// Design
import {
  TextField,
  Tooltip,
  IconButton,
  MenuItem,
  FormControl,
  Select,
  InputLabel,
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { Box } from '@mui/system';
import CircularProgress from '@mui/material/CircularProgress';
import MoveUpIcon from '@mui/icons-material/MoveUp';
import EditNotificationsIcon from '@mui/icons-material/EditNotifications';

// Internal
import createAccess from '../../../../../utils/functions/createAccess';
import API from '../../../../../utils/api';
import { appSupervision } from '../../../../../store/app/slice';

// Third-party
import { useSelector } from 'react-redux';

function TransferDialog({ open, setOpen, handleTransferChatsToLine }) {
  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {'Transferir todas as conversas para a fila?'}
      </DialogTitle>
      <DialogActions>
        <Button onClick={() => setOpen(false)} color="primary">
          Cancelar
        </Button>
        <Button
          onClick={() => {
            setOpen(false);
            handleTransferChatsToLine();
          }}
          color="primary"
          autoFocus
        >
          Transferir
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default function FilterField({
  getActiveFilter,
  user,
  loadingChats,
  keyWords,
  setKeyWords,
  setOpenNotificationDialog,
}) {
  const [keyWordsOpened, setKeyWordsOpened] = useState([]);
  const [keyWordsOpened2, setKeyWordsOpened2] = useState([]);
  const _appSupervision = useSelector(appSupervision);

  const [compoundFilter1, setCompoundFilter1] = useState('');
  const [compoundFilter2, setCompoundFilter2] = useState('');

  const [openTransferDialog, setOpenTransferDialog] = useState(false);

  const handleSelectFilter = (letter) => {
    setKeyWords(letter);
  };

  const handleTransferChatsToLine = () => {
    let access_click2chat = createAccess(user);
    let id = keyWords;
    API.put
      .transferAllChatsToLine(access_click2chat, id)
      .then(() => {})
      .finally(() => {});
  };

  useEffect(() => {
    if (compoundFilter1 && compoundFilter2) {
      let compound = `${compoundFilter1}&&&${compoundFilter2}`;
      handleSelectFilter(compound);
      getActiveFilter(compound, 'closedXoperator');
    }
    // eslint-disable-next-line
  }, [compoundFilter1, compoundFilter2]);

  useEffect(() => {
    setKeyWords('');
    if (_appSupervision?.filterType === 'name') {
    } else if (_appSupervision?.filterType === 'teams') {
      let access_click2chat = createAccess(user);
      API.get
        .teams(access_click2chat)
        .then(async (response) => {
          if (response.status === 200) {
            let _keyWords = [
              { text: 'Tudo', filter: 'all' },
              // {text: '', filter: ''},
            ];

            let newFilter = response.data.info;
            newFilter = Object.keys(newFilter).map((el) => {
              return {
                text: newFilter[el].name,
                filter: el,
              };
            });
            _keyWords = _keyWords.concat(newFilter);
            setKeyWordsOpened(_keyWords);
          } else {
            setKeyWordsOpened([
              { text: 'Tudo', filter: 'all' },
              // {text: '', filter: ''},
            ]);
          }
        })
        .finally(() => {
          // setOperatorEditDialogShow(false);
        });
    } else if (['opened', 'starred'].includes(_appSupervision?.filterType)) {
      let access_click2chat = createAccess(user);
      API.get
        .operatorsListSupervision(access_click2chat)
        .then(async (response) => {
          if (response.status === 200) {
            let _keyWords = [
              { text: 'Tudo', filter: 'all' },
              // {text: '', filter: ''},
              { text: 'Vazios', filter: 'empty' },
              { text: 'Robô', filter: 'option_bot' },
            ];

            let supervisorsList = await API.get.supervisorsListSupervision(
              access_click2chat
            );
            supervisorsList = supervisorsList?.data?.info ?? [];
            let newFilter = response.data.info;
            newFilter = newFilter.concat(supervisorsList);
            newFilter = newFilter.map((el) => {
              return {
                text: el.username,
                filter: el.operator_id,
              };
            });
            _keyWords = _keyWords.concat(newFilter);
            setKeyWordsOpened(_keyWords);
          } else {
            setKeyWordsOpened([
              { text: 'Tudo', filter: 'all' },
              // {text: '', filter: ''},
              { text: 'Vazios', filter: 'empty' },
              { text: 'Robô', filter: 'option_bot' },
            ]);
          }
        })
        .finally(() => {
          // setOperatorEditDialogShow(false);
        });
    } else if (_appSupervision?.filterType === 'closedXoperator') {
      let access_click2chat = createAccess(user);
      API.get
        .operatorsListSupervision(access_click2chat)
        .then((response) => {
          if (response.status === 200) {
            let _keyWords = [
              { text: 'Tudo', filter: 'all' },
              // {text: '', filter: ''},
              // {text: 'Vazios', filter: 'empty'},
              { text: 'Robô', filter: 'option_bot' },
            ];
            let newFilter = response.data.info;
            newFilter = newFilter.map((el) => {
              return {
                text: el.username,
                filter: el.operator_id,
              };
            });
            _keyWords = _keyWords.concat(newFilter);
            setKeyWordsOpened(_keyWords);
          } else {
            setKeyWordsOpened([
              { text: 'Tudo', filter: 'all' },
              // {text: '', filter: ''},
              // {text: 'Vazios', filter: 'empty'},
              { text: 'Robô', filter: 'option_bot' },
            ]);
          }
        })
        .finally(() => {
          // setOperatorEditDialogShow(false);
        });
      API.get
        .closeReasonsList(access_click2chat)
        .then((response) => {
          if (response?.data?.info?.list) {
            let _keyWords = [
              { text: 'Tudo', filter: 'all' },
              { text: 'Inatividade', filter: 'inactivity' },
              // {text: '', filter: ''},
              // {text: 'Vazios', filter: 'empty'},
              // {text: 'Robô', filter: 'option_bot'}
            ];
            let newFilter = response.data.info.list;
            newFilter = newFilter
              .filter((el) => el.active)
              .map((el) => {
                return {
                  text: el.reason,
                  filter: el.id,
                };
              });
            _keyWords = _keyWords.concat(newFilter);
            setKeyWordsOpened2(_keyWords);
          } else {
            setKeyWordsOpened2([
              { text: 'Tudo', filter: 'all' },
              { text: 'Inatividade', filter: 'inactivity' },
              // {text: '', filter: ''},
              // {text: 'Vazios', filter: 'empty'},
              // {text: 'Robô', filter: 'option_bot'}
            ]);
          }
        })
        .finally(() => {
          // setOperatorEditDialogShow(false);
        });
    } else if (_appSupervision?.filterType === 'inbox') {
      handleSelectFilter('supervisor');
    } else if (_appSupervision?.filterType === 'lines') {
      let access_click2chat = createAccess(user);
      API.get.holdLineConsolidation(access_click2chat).then((response) => {
        if (response.status === 200) {
          let teamsTemp = response?.data?.info?.teams ?? {};
          let _keyWords = Object.keys(teamsTemp).map((el) => {
            return {
              text: `${teamsTemp[el].name} (${teamsTemp[el].qtt})`,
              filter: el,
            };
          });
          setKeyWordsOpened(_keyWords);
          console.log({ teamsTemp, _keyWords });
        } else {
          //   setChatsListRequest([])
        }
      });
    }
    // eslint-disable-next-line
  }, [_appSupervision?.filterType]);

  const handleOpenTransferDialog = () => {
    setOpenTransferDialog(true);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        width: '100%',
        flexDirection: 'column',
        paddingTop: '1rem',
      }}
    >
      {loadingChats && <CircularProgress />}
      {_appSupervision?.filterType === 'name' && !loadingChats && (
        <>
          <Box>
            <TextField
              id="standard-basic"
              label="Nome/Número"
              variant="outlined"
              style={{ width: '19rem' }}
              value={keyWords}
              onChange={(e) => handleSelectFilter(e.target.value)}
            />
          </Box>
          <Box>
            <Tooltip title="Procurar">
              <span>
                <IconButton
                  size="small"
                  disabled={keyWords.length < 3}
                  onClick={() => getActiveFilter(keyWords, 'name')}
                >
                  <SearchIcon />
                </IconButton>
              </span>
            </Tooltip>
          </Box>
        </>
      )}
      {_appSupervision?.filterType === 'opened' && !loadingChats && (
        <>
          {keyWordsOpened.length > 0 && (
            <>
              <FormControl
              // className={classes.formControl}
              >
                <InputLabel id="filter_input">Filtro</InputLabel>
                <Select
                  labelId="filter_input"
                  id="filter-select"
                  value={keyWords}
                  onChange={(e) => {
                    handleSelectFilter(e.target.value);
                    getActiveFilter(e.target.value, 'opened');
                  }}
                  style={{ whiteSpace: 'nowrap', width: '19rem' }}
                >
                  {keyWordsOpened.map((f) => (
                    <MenuItem value={f.filter} key={f.filter || '0'}>
                      {f.text}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Tooltip title="Transferir todas as conversas para a fila">
                <span>
                  <IconButton
                    onClick={handleOpenTransferDialog}
                    disabled={!keyWords}
                  >
                    <MoveUpIcon />
                  </IconButton>
                </span>
              </Tooltip>
            </>
          )}
        </>
      )}
      {_appSupervision?.filterType === 'closedXoperator' && !loadingChats && (
        <>
          {keyWordsOpened.length > 0 && (
            <FormControl
            // className={classes.formControl}
            >
              <InputLabel id="filter_input">Filtro de Operadores</InputLabel>
              <Select
                labelId="filter_input"
                id="filter-select"
                value={compoundFilter1}
                onChange={(e) => setCompoundFilter1(e.target.value)}
                style={{ whiteSpace: 'nowrap', width: '19rem' }}
              >
                {keyWordsOpened.map((f) => (
                  <MenuItem value={f.filter} key={f.filter || '0'}>
                    {f.text}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          {keyWordsOpened2.length > 0 && (
            <FormControl
            // className={classes.formControl}
            >
              <InputLabel id="filter_input">Filtro de Tabulação</InputLabel>
              <Select
                labelId="filter_input"
                id="filter-select"
                value={compoundFilter2}
                onChange={(e) => setCompoundFilter2(e.target.value)}
                style={{ whiteSpace: 'nowrap', width: '19rem' }}
              >
                {keyWordsOpened2.map((f) => (
                  <MenuItem value={f.filter} key={f.filter || '0'}>
                    {f.text}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          {/* Bar with some controls */}
          {_appSupervision?.supervisorCanDoActionsToClosedChats && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '10px',
                width: '100%',
                flexDirection: 'row',
                paddingTop: '1rem',
              }}
            >
              <Tooltip title="Enviar mensagem para os chats selecionados">
                <span>
                  <IconButton onClick={() => setOpenNotificationDialog(true)}>
                    <EditNotificationsIcon />
                  </IconButton>
                </span>
              </Tooltip>
            </Box>
          )}
        </>
      )}
      {_appSupervision?.filterType === 'teams' && !loadingChats && (
        <>
          {keyWordsOpened.length > 0 && (
            <FormControl
            // className={classes.formControl}
            >
              <InputLabel id="filter_input">Filtro de Times</InputLabel>
              <Select
                labelId="filter_input"
                id="filter-select"
                value={keyWords}
                onChange={(e) => {
                  handleSelectFilter(e.target.value);
                  getActiveFilter(e.target.value, 'teams');
                }}
                style={{ whiteSpace: 'nowrap', width: '19rem' }}
              >
                {keyWordsOpened.map((f) => (
                  <MenuItem value={f.filter} key={f.filter || '0'}>
                    {f.text}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </>
      )}
      {_appSupervision?.filterType === 'starred' && !loadingChats && (
        <>
          {keyWordsOpened.length > 0 && (
            <FormControl
            // className={classes.formControl}
            >
              <InputLabel id="filter_input">Filtro</InputLabel>
              <Select
                labelId="filter_input"
                id="filter-select"
                value={keyWords}
                onChange={(e) => {
                  handleSelectFilter(e.target.value);
                  getActiveFilter(e.target.value, 'starred');
                }}
                style={{ whiteSpace: 'nowrap', width: '19rem' }}
              >
                {keyWordsOpened.map((f) => (
                  <MenuItem value={f.filter} key={f.filter || '0'}>
                    {f.text}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </>
      )}
      {_appSupervision?.filterType === 'lines' && !loadingChats && (
        <>
          {keyWordsOpened.length > 0 && (
            <FormControl
            // className={classes.formControl}
            >
              <InputLabel id="filter_input">Filtro de Filas</InputLabel>
              <Select
                labelId="filter_input"
                id="filter-select"
                value={keyWords}
                onChange={(e) => {
                  handleSelectFilter(e.target.value);
                  getActiveFilter(e.target.value, 'lines');
                }}
                style={{ whiteSpace: 'nowrap', width: '19rem' }}
              >
                {keyWordsOpened.map((f) => (
                  <MenuItem value={f.filter} key={f.filter || '0'}>
                    {f.text}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </>
      )}
      <TransferDialog
        open={openTransferDialog}
        setOpen={setOpenTransferDialog}
        handleTransferChatsToLine={handleTransferChatsToLine}
      />
    </Box>
  );
}
