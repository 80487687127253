import apisauce from 'apisauce';
import store from '../../store';
import { signout } from '../../store/app/slice';
import packageJson from '../../../package.json';
const identifier = packageJson.identifier;
const public_key = packageJson.public_key;

// const IDENTIFIER = packageJson.identifier;

const instance = apisauce.create({
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});

instance.axiosInstance.interceptors.request.use(
  (config) => {
    const token = store.getState().app.user
      ? store.getState().app.user.token
      : '';
    config.headers.Authorization = token ? `Bearer ${token}` : '';
    config.headers.app_identifier = identifier;
    config.headers.app_public_key = public_key;

    return config;
  }
  //    (error) => {
  //        console.log({error})
  //         if (error.status === 401) {
  //             store.getState().app = appInitialState;
  //             store.getState().auth = authInitialState;
  //             store.getState().dataset = datasetInitialState;
  //             store.getState().package = packageInitialState;
  //             store.getState().flow = flowInitialState;
  //             store.getState().training = trainingInitialState;
  //         }
  //     }
);

instance.axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error && error.response && error.response.status === 401) {
      // store.dispatch(clearDatasetAll());
      // store.dispatch(clearPackageAll());
      // store.dispatch(clearFlowAll());
      // store.dispatch(clearTrainingAll());
      // store.dispatch(clearAppAll());
      store.dispatch(signout());
      // store.dispatch(setNotificationBarOpen({open: true, type: 'warning', message: error.response.data.error.name}));
    } else {
      return Promise.reject(error);
    }
  }
);

const api = {
  get: {
    qrCode: (vm, company_id) =>
      instance.get(
        `${window.WS_URL}/admin/vms/qr-code?vm=${vm}&company_id=${company_id}`
      ),
    companyLogo: (companyName) =>
      instance.get(
        `${window.WS_URL}/admin/company/logo?company_name=${companyName}`
      ),
    chats: (access_click2chat) =>
      instance.get(`${JSON.parse(access_click2chat).url}/operator/chats`),
    chatsByNameOperators: (access_click2chat, filter) =>
      instance.get(
        `${JSON.parse(access_click2chat).url}/operators/chats/by-name`,
        {
          filter,
        }
      ),

    chat: (access_click2chat, chat_id) =>
      instance.get(
        `${JSON.parse(access_click2chat).url}/chats/chat/${chat_id}`
      ),
    chatHistory: (access_click2chat, chat_id) =>
      instance.get(
        `${JSON.parse(access_click2chat).url}/chats/chat-history/${chat_id}`
      ),
    file: (access_click2chat, file_id, media_key) =>
      instance.get(
        `${
          JSON.parse(access_click2chat).files_url
        }/file/${file_id}?validation=user&media_key=${media_key}`
      ),

    filesCloudList: (access_click2chat) =>
      instance.get(
        `${JSON.parse(access_click2chat).url}/files/files-cloud-list`
      ),

    operatorsList: (access_click2chat) =>
      instance.get(`${JSON.parse(access_click2chat).url}/chats/operators/list`),

    vmsStatus: (access_click2chat) =>
      instance.get(`${JSON.parse(access_click2chat).url}/status/list`),
    operatorsStatus: (access_click2chat) =>
      instance.get(`${JSON.parse(access_click2chat).url}/operators/list`),

    scheduleOperatorSupervision: (access_click2chat, operatorId) =>
      instance.get(
        `${
          JSON.parse(access_click2chat).url
        }/supervision/operator/${operatorId}/schedule`
      ),
    clockPunchOperatorsSupervision: (access_click2chat, filter) =>
      instance.get(
        `${
          JSON.parse(access_click2chat).url
        }/supervision/operators/clock-punch?dates=${filter}`
      ),
    serviceTicketsSupervision: (access_click2chat, filter) =>
      instance.get(
        `${
          JSON.parse(access_click2chat).url
        }/supervision/service-tickets?dates=${filter}`
      ),
    waitingInLineLogsSupervision: (access_click2chat, filter) =>
      instance.get(
        `${
          JSON.parse(access_click2chat).url
        }/supervision/waiting-in-line-log?dates=${filter}`
      ),
    apiCountUsageSupervision: (access_click2chat, filter) =>
      instance.get(
        `${
          JSON.parse(access_click2chat).url
        }/supervision/api-count-usage?dates=${filter}`
      ),
    messagesConsolidation: (access_click2chat, time) =>
      instance.get(
        `${
          JSON.parse(access_click2chat).url
        }/messages/consolidation?filter=${time}`
      ),
    closedReasonsConsolidation: (access_click2chat, time) =>
      instance.get(
        `${
          JSON.parse(access_click2chat).url
        }/chats/closed-reasons/consolidation?filter=${time}`
      ),
    firstMessageConsolidation: (access_click2chat, time) =>
      instance.get(
        `${
          JSON.parse(access_click2chat).url
        }/chats/creation/consolidation?filter=${time}`
      ),
    returnsConsolidation: (access_click2chat, time) =>
      instance.get(
        `${
          JSON.parse(access_click2chat).url
        }/chats/returns/consolidation?filter=${time}`
      ),
    holdLineConsolidation: (access_click2chat, time) =>
      instance.get(
        `${
          JSON.parse(access_click2chat).url
        }/customer/hold-line/?filter=${time}`
      ),
    closeReasonsList: (access_click2chat) =>
      instance.get(
        `${JSON.parse(access_click2chat).url}/supervision/close-reasons`
      ),
    currentStatusChats: (access_click2chat) =>
      instance.get(`${JSON.parse(access_click2chat).url}/chats/current-status`),

    chatsByName: (access_click2chat, filter) =>
      instance.get(
        `${JSON.parse(access_click2chat).url}/supervision/chats/by-name`,
        {
          filter,
        }
      ),
    chatsByUser: (access_click2chat, filter, starred = false) =>
      instance.get(`${JSON.parse(access_click2chat).url}/supervision/chats`, {
        filter,
        starred,
      }),
    chatsByCloseXOperator: (access_click2chat, filter) =>
      instance.get(
        `${
          JSON.parse(access_click2chat).url
        }/supervision/chats/closed-x-operator`,
        { filter }
      ),
    chatsByTeams: (access_click2chat, filter) =>
      instance.get(
        `${JSON.parse(access_click2chat).url}/supervision/chats/teams`,
        {
          filter,
        }
      ),
    chatsInLines: (access_click2chat, filter) =>
      instance.get(
        `${JSON.parse(access_click2chat).url}/supervision/chats/in-lines`,
        {
          filter,
        }
      ),
    chatsBySupervisor: (access_click2chat) =>
      instance.get(
        `${JSON.parse(access_click2chat).url}/supervision/chats/supervisor`,
        {}
      ),
    chatsOpenByFilter: (access_click2chat, filter) =>
      instance.get(
        `${JSON.parse(access_click2chat).url}/supervision/chats/open`,
        {
          filter,
        }
      ),

    chatSupervision: (access_click2chat, _chat_id) =>
      instance.get(
        `${
          JSON.parse(access_click2chat).url
        }/supervision/chats/chat/${_chat_id}`
      ),
    nextOperatorListSupervision: (access_click2chat) =>
      instance.get(
        `${JSON.parse(access_click2chat).url}/supervision/operators/list`
      ),
    operatorsListSupervision: (access_click2chat) =>
      instance.get(`${JSON.parse(access_click2chat).url}/operators/list`),

    supervisorsListSupervision: (access_click2chat) =>
      instance.get(
        `${JSON.parse(access_click2chat).url}/supervision/list-supervisors`
      ),

    scheduleOperator: (access_click2chat) =>
      instance.get(`${JSON.parse(access_click2chat).url}/operator/schedule`),
    scheduleChat: (access_click2chat, phone_number) =>
      instance.get(
        `${
          JSON.parse(access_click2chat).url
        }/chats/schedule&phone_number=${phone_number}`
      ),

    fileTypes: (access_click2chat) =>
      instance.get(`${JSON.parse(access_click2chat).url}/check/file-types`),

    teams: (access_click2chat) =>
      instance.get(`${JSON.parse(access_click2chat).url}/teams`),
    tags: (access_click2chat) =>
      instance.get(`${JSON.parse(access_click2chat).url}/tags`),
    pausesList: (access_click2chat) =>
      instance.get(
        `${JSON.parse(access_click2chat).url}/operators/pauses/list`
      ),
    lastCampaignMassive: (access_click2chat) =>
      instance.get(
        `${JSON.parse(access_click2chat).url}/massive/last-campaign`
      ),
    dayCampaignsMassive: (access_click2chat, filter) =>
      instance.get(
        `${
          JSON.parse(access_click2chat).url
        }/massive/day-campaigns&filter=${filter}`
      ),

    internalCommunicationUsers: (access_click2chat) =>
      instance.get(
        `${JSON.parse(access_click2chat).url}/internal-communication/users`
      ),
    internalCommunicationUsersOverview: (access_click2chat, operatorId) =>
      instance.get(
        `${
          JSON.parse(access_click2chat).url
        }/internal-communication/users-overview&operatorId=${operatorId}`
      ),
    internalChat: (access_click2chat, id) =>
      instance.get(
        `${
          JSON.parse(access_click2chat).url
        }/internal-communication/chat&id=${id}`
      ),
    internalChatOverview: (access_click2chat, id, operatorId) =>
      instance.get(
        `${
          JSON.parse(access_click2chat).url
        }/internal-communication/chat-overview?id=${id}&operatorId=${operatorId}`
      ),
    internalChats: (access_click2chat) =>
      instance.get(
        `${JSON.parse(access_click2chat).url}/internal-communication/chats`
      ),
    internalSupervisorCount: (access_click2chat) =>
      instance.get(
        `${
          JSON.parse(access_click2chat).url
        }/internal-communication/supervisor-count`
      ),

    serviceTicketsChatsByDate: (access_click2chat, date) =>
      instance.get(
        `${
          JSON.parse(access_click2chat).url
        }/service-tickets/chats-by-date?date=${date}`
      ),
    lineDistributionThroughLineAll: (access_click2chat, filter) =>
      instance.get(
        `${
          JSON.parse(access_click2chat).url
        }/supervision/line-distribution-through-line-all?dates=${filter}`
      ),
  },
  post: {
    login: (access_click2chat) =>
      instance.post(`${window.WS_URL}/security/login`, { access_click2chat }),
    clockPunch: (accessClick2chat, cpStatus) =>
      instance.post(
        `${
          JSON.parse(accessClick2chat).url
        }/operator/clock-punch?status=${cpStatus}&access_click2chat=${accessClick2chat}`,
        {}
      ),
    message: (
      access_click2chat,
      chat_id,
      msg,
      hasMedia,
      file_id,
      team_id,
      team_name
    ) =>
      instance.post(
        `${JSON.parse(access_click2chat).url}/chats/interaction/${chat_id}`,
        {
          msg,
          hasMedia,
          file_id,
          team_id,
          team_name,
        }
      ),
    fileResend: (access_click2chat, chat_id, filename, resend, file_id) =>
      instance.post(
        `${
          JSON.parse(access_click2chat).url
        }/chats/file/interaction/${chat_id}`,
        {
          filename: filename,
          resend: resend,
          file_id: file_id,
        }
      ),
    file: (access_click2chat, _file) =>
      instance.post(
        `${JSON.parse(access_click2chat).files_url}/file?validation=user`,
        {
          _file,
        }
      ),
    schedule: (
      access_click2chat,
      dateToSend,
      selectedNumber,
      textToSend,
      selectedChatID,
      hasFile,
      files,
      name,
      ddi,
      cpfCnpj,
      email
    ) =>
      instance.post(
        `${JSON.parse(access_click2chat).url}/messages/schedule/interaction`,
        {
          date: dateToSend,
          phone: selectedNumber.trim(),
          msg: textToSend,
          selected_chat_id: selectedChatID || '',
          hasFile: hasFile,
          files: files,
          customerName: name,
          ddi,
          cpf_cnpj: cpfCnpj,
          email,
        }
      ),
    note: (access_click2chat, title, text) =>
      instance.post(`${JSON.parse(access_click2chat).url}/operator/note`, {
        title,
        text,
      }),
    closeChat: (access_click2chat, reason, phone_number) =>
      instance.post(`${JSON.parse(access_click2chat).url}/chats/chat/close`, {
        phone_number,
        reason,
      }),

    clockPunchSupervisionIn: (accessClick2chat) =>
      instance.post(
        `${
          JSON.parse(accessClick2chat).url
        }/supervision/clock-punch/in?access_click2chat=${accessClick2chat}`,
        {}
      ),
    clockPunchSupervisionOut: (accessClick2chat) =>
      instance.post(
        `${JSON.parse(accessClick2chat).url}/supervision/clock-punch/out`,
        {}
      ),
    reason: (
      access_click2chat,
      reason,
      evaluation,
      color,
      endingMessage,
      internalCode,
      teams
    ) =>
      instance.post(
        `${JSON.parse(access_click2chat).url}/supervision/close-reason`,
        {
          reason,
          evaluation,
          color,
          endingMessage,
          internalCode,
          teams,
        }
      ),
    massive: (
      access_click2chat,
      massive_list,
      date_to_send,
      hasFile,
      files
      // _teamId,
      // _teamName
    ) =>
      instance.post(`${JSON.parse(access_click2chat).url}/massive`, {
        massive_list,
        date_to_send,
        files,
        hasFile,
        // teamId: _teamId,
        // teamName: _teamName,
      }),
    massiveV2: (
      access_click2chat,
      massive_lists
      // date_to_send,
      // hasFile,
      // files
      // _teamId,
      // _teamName
    ) =>
      instance.post(`${JSON.parse(access_click2chat).url}/massive-v2`, {
        massive_lists,
        // date_to_send,
        // files,
        // hasFile,
        // teamId: _teamId,
        // teamName: _teamName,
      }),
    internalMessage: (access_click2chat, id, msg) =>
      instance.post(
        `${JSON.parse(access_click2chat).url}/internal-communication/message`,
        {
          id,
          msg,
        }
      ),
    whisper: (access_click2chat, id, msg) =>
      instance.post(
        `${JSON.parse(access_click2chat).url}/chats/whisper/${id}`,
        {
          id,
          msg,
        }
      ),
  },
  put: {
    star: (access_click2chat, star, phone_number) =>
      instance.put(
        `${JSON.parse(access_click2chat).url}/chats/star/${phone_number}`,
        {
          starred: star,
        }
      ),
    contactName: (
      access_click2chat,
      contact_name,
      cpf_cnpj,
      email,
      phone_number
    ) =>
      instance.put(`${JSON.parse(access_click2chat).url}/customer/name`, {
        contact_name,
        phone_number,
        cpf_cnpj,
        email,
      }),
    note: (access_click2chat, title, text, id) =>
      instance.put(`${JSON.parse(access_click2chat).url}/operator/note`, {
        title,
        text,
        id,
      }),
    nextOperator: (access_click2chat, operator_id, phone_number) =>
      instance.put(
        `${JSON.parse(access_click2chat).url}/chats/operators/next`,
        {
          operator_id,
          phone_number,
        }
      ),
    nextSupervisor: (access_click2chat, supervisor_id, phone_number) =>
      instance.put(
        `${JSON.parse(access_click2chat).url}/chats/operators/next-supervisor`,
        {
          supervisor_id,
          phone_number,
        }
      ),
    pauseOperator: (access_click2chat, statusPause, pauseId) =>
      instance.put(`${JSON.parse(access_click2chat).url}/operators/pause`, {
        statusPause,
        pauseId,
      }),

    updatePassword: (access_click2chat, new_password) =>
      instance.put(`${window.WS_URL}/security/password/update`, {
        new_password,
      }),

    pullFromLine: (access_click2chat, operator_id, username) =>
      instance.put(`${JSON.parse(access_click2chat).url}/operator/pull`, {
        operator_id,
        username,
      }),
    putInLine: (access_click2chat, operator_id, username) =>
      instance.put(`${JSON.parse(access_click2chat).url}/operator/put`, {
        operator_id,
        username,
      }),
    reason: (
      access_click2chat,
      reason,
      id,
      active,
      evaluation,
      color,
      endingMessage,
      internalCode,
      teams
    ) =>
      instance.put(
        `${JSON.parse(access_click2chat).url}/supervision/close-reason`,
        {
          reason,
          id,
          active,
          evaluation,
          color,
          endingMessage,
          internalCode,
          teams,
        }
      ),

    nextOperatorSupervision: (
      access_click2chat,
      operator_id,
      old_operator,
      phone_number
    ) =>
      instance.put(
        `${JSON.parse(access_click2chat).url}/supervision/chats/operators/next`,
        {
          operator_id,
          old_operator,
          phone_number,
        }
      ),
    nextSupervisorSupervision: (
      access_click2chat,
      supervisor_id,
      old_operator,
      phone_number
    ) =>
      instance.put(
        `${
          JSON.parse(access_click2chat).url
        }/supervision/chats/operators/next-supervisor`,
        { supervisor_id, old_operator, phone_number }
      ),
    schedule: (access_click2chat, reminder_id) =>
      instance.put(
        `${
          JSON.parse(access_click2chat).url
        }/messages/schedule/resend/${reminder_id}`,
        {}
      ),

    blockOperator: (access_click2chat, operatorId) =>
      instance.put(
        `${JSON.parse(access_click2chat).url}/supervision/operator/block`,
        {
          operatorId,
        }
      ),
    logoutOperator: (access_click2chat, operatorId) =>
      instance.put(
        `${JSON.parse(access_click2chat).url}/supervision/operator/logout`,
        {
          operatorId,
        }
      ),
    unblockOperator: (access_click2chat, operatorId) =>
      instance.put(
        `${JSON.parse(access_click2chat).url}/supervision/operator/unblock`,
        {
          operatorId,
        }
      ),

    teamUser: (access_click2chat, operatorId, teamId, teamName) =>
      instance.put(
        `${JSON.parse(access_click2chat).url}/team/update-team-operator`,
        {
          operatorId,
          teamId,
          teamName,
        }
      ),
    teamVM: (access_click2chat, vmId, teamId, teamName, direction) =>
      instance.put(`${JSON.parse(access_click2chat).url}/team/update-team-vm`, {
        vmId,
        teamId,
        teamName,
        direction,
      }),
    tagVM: (access_click2chat, vmId, tagId, tagName) =>
      instance.put(`${JSON.parse(access_click2chat).url}/tag/vm`, {
        vmId,
        tagId,
        tagName,
      }),

    transferAllChatsToLine: (access_click2chat, operatorId) =>
      instance.put(
        `${JSON.parse(access_click2chat).url}/supervision/chats/transfer/line`,
        {
          operatorId,
        }
      ),

    blockChat: (access_click2chat, phone_number, status) =>
      instance.put(`${JSON.parse(access_click2chat).url}/chats/block-chat`, {
        phone_number,
        status,
      }),

    changeVMChat: ({ access_click2chat, phone_number, vm }) =>
      instance.put(`${JSON.parse(access_click2chat).url}/chats/change-vm`, {
        phone_number,
        vm,
      }),

    notifyUsers: ({ access_click2chat, numbers, message }) =>
      instance.put(
        `${JSON.parse(access_click2chat).url}/supervision/notify-users`,
        {
          numbers,
          message,
        }
      ),
  },
  delete: {
    note: (access_click2chat, id) =>
      instance.delete(
        `${JSON.parse(access_click2chat).url}/operator/note&id=${id}`
      ),

    operator: (access_click2chat, operator_id) =>
      instance.delete(`${window.WS_URL}/operator/delete`, { operator_id }),
    schedule: (access_click2chat, reminder_id) =>
      instance.delete(
        `${JSON.parse(access_click2chat).url}/messages/schedule/${reminder_id}`,
        {}
      ),
  },
};

export default api;
