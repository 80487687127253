// React
import React, { useEffect, useState } from 'react';

// Design
import { withStyles, makeStyles } from '@mui/styles';
import InputLabel from '@mui/material/InputLabel';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import MuiDialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Tooltip, Box } from '@mui/material';

// Internal
import { setCloseReasonList } from '../../store/app/slice';
import createAccess from '../../utils/functions/createAccess';
import API from '../../utils/api';
import { appUser } from '../../store/app/slice';

// Third-Party
import { useDispatch, useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 120,
  },
}));

function SimpleSelect({ reason, setReason, closeReasonList }) {
  const classes = useStyles();

  const handleChange = (event) => {
    setReason(event.target.value);
  };

  return (
    <Box>
      {closeReasonList.length > 0 && (
        <FormControl className={classes.formControl}>
          <InputLabel id="demo-simple-select-label">Tabulação</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={reason}
            onChange={handleChange}
            style={{ whiteSpace: 'nowrap', width: '14rem' }}
          >
            {closeReasonList.map((reason) => (
              <MenuItem value={reason.id} key={reason.id}>
                {reason.reason}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    </Box>
  );
}

const styles = (theme) => ({
  root: {
    margin: 0,
  },
  closeButton: {
    position: 'absolute',
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <Tooltip title="Fechar Janela" placement="left-end">
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        </Tooltip>
      ) : null}
    </MuiDialogTitle>
  );
});

export default function CloseConfirmDialog({
  open,
  setOpen,
  phoneNumber,
  closeReasonList,
  setReminder,
}) {
  const dispatch = useDispatch();
  const user = useSelector(appUser);
  const [reason, setReason] = useState('');
  const [ableToSend, setAbleToSend] = useState(false);

  const handleCloseChat = () => {
    setAbleToSend(false);
    API.post
      .closeChat(createAccess(user), reason, phoneNumber)
      .then((response) => {
        if (user.worker === 'operator') {
          dispatch(setCloseReasonList(response?.data?.info?.closeReasonList));
        }
      })
      .finally(() => {
        setAbleToSend(true);
        setOpen(false);
      });
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (reason) {
      setAbleToSend(true);
    } else {
      setAbleToSend(false);
    }
  }, [reason]);

  return (
    <Dialog
      onClose={handleClose}
      fullWidth={true}
      // maxWidth='lg'
      open={open}
      sx={
        {
          // zIndex: 10000,
        }
      }
    >
      <DialogTitle id="customized-dialog-title">
        Encerrar Atendimento
      </DialogTitle>
      <Box
        sx={{
          display: 'flex',
          margin: '1rem 1rem 1rem 1rem',
          flexDirection: 'column',
        }}
      >
        <Box>
          <Typography gutterBottom>
            Você tem certeza que deseja encerrar o atendimento?
          </Typography>
          <Typography gutterBottom>
            Já agendou o preventivo?
            {Boolean(setReminder) && (
              <Button
                onClick={setReminder}
                color="primary"
                disabled={!setReminder}
              >
                Agendar
              </Button>
            )}
          </Typography>
          <Typography gutterBottom>
            Se tem certeza, é só escolher a tabulação e clicar em ENCERRAR, se
            não clique em NÃO ENCERRAR ou feche esta janela.
          </Typography>
        </Box>
        <Box
          sx={{
            alignSelf: 'flex-end',
          }}
        >
          {Array.isArray(closeReasonList) && (
            <>
              <SimpleSelect
                setReason={setReason}
                reason={reason}
                closeReasonList={closeReasonList}
              />
              <Button autoFocus onClick={handleClose} color="primary">
                Não Encerrar
              </Button>
              <Button
                autoFocus
                onClick={handleCloseChat}
                color="secondary"
                disabled={!reason || !ableToSend}
              >
                Encerrar
              </Button>
            </>
          )}
        </Box>
      </Box>
    </Dialog>
  );
}
