// React
import React, { useEffect, useRef, useState } from 'react';

// Design

// Internal
import {
  appUser,
  getChats,
  appOperation,
  setSelectedChat,
  getChat,
  setFetchingChat,
  appFetchingChat,
  appFetchingChats,
  getChatHistory,
} from '../../store/app/slice';
import API from '../../utils/api';

import OperationBar from './Components/operationBar';

import ChatsStructure from './Components/chatsStructure';
import NotificationBar from '../../components/Feedback/notification';

import FilesDialog from '../../components/Dialogs/filesDialog';
import PathDialog from '../../components/Dialogs/pathDialog';
import EditContactDialog from '../../components/Dialogs/editContactDialog';
import ReminderDialog from '../../components/Dialogs/reminderDialog';
import NotesDialog from '../../components/Dialogs/notesDialog';
import NextOperatorDialog from '../../components/Dialogs/nextOperatorDialog';
import CloseConfirmDialog from '../../components/Dialogs/closeConfirmDialog';
import ScheduleDialog from '../../components/Dialogs/scheduleDialog';
import ScheduleChatDialog from '../../components/Dialogs/scheduleChatDialog';
import LeavePauseDialog from '../../components/Dialogs/leavePauseDialog';

import createAccess from '../../utils/functions/createAccess';
import downloadURI from '../../utils/functions/downloadURI';
import fileTob64 from '../../utils/functions/filetob64';
import b64toBlob from '../../utils/functions/b64toBlob';
import openURI from '../../utils/functions/openURI';

import PauseDialog from '../../components/Dialogs/pauseDialog';
import ProfileOperatorDialog from '../../components/Dialogs/profileOperatorDialog';
import RecordAudioDialog from '../../components/Dialogs/recordAudioDialog';
import makeid from '../../utils/functions/makeid';
import CloudFilesDialog from '../../components/Dialogs/cloudFilesDialog';

import SearchForContactDialog from '../../components/Dialogs/searchForContactDialog';
import ContactsPerDateDialog from '../../components/Dialogs/contactsPerDateDialog';
import ProfilePicOpenDialog from '../../components/Dialogs/profilePicOpenDialog';

// Third-party
import { useSelector, useDispatch } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';

export default function Operation() {
  const dispatch = useDispatch();
  const user = useSelector(appUser);
  const timeToUpdate = 2000;
  const operation = useSelector(appOperation);
  const selectedChatList = useSelector(appOperation).selectedChatList;
  const chatsList = useSelector(appOperation).chatsLists[selectedChatList];
  const allChatsLists = useSelector(appOperation).chatsLists;
  const selectedChat = useSelector(appOperation).selectedChat;
  const localFiles = useSelector(appOperation).files;
  const fetchingChat = useSelector(appFetchingChat);
  const fetchingChats = useSelector(appFetchingChats);
  const notes = useSelector(appOperation).notes;
  const acceptedFiles = useSelector(appOperation).accepted_files;
  const closeReasonList = useSelector(appOperation).closeReasonList;
  const statusPause = useSelector(appOperation)?.statusPause;
  const getNewData = useRef(fetchingChats);
  // const _chatsHash = useSelector(appOperation)?.chatsHash;
  // const [_chatsList, setChatsList] = useState([]);

  let cD = new Date();
  let _hour = cD.getHours();
  cD.setHours(_hour - 3);
  const [hourSelectedReminder, setHourSelectedReminder] = useState(
    cD.toISOString().slice(0, 16)
  );
  const [lastInteractionTime, setLastInteractionTime] = useState(new Date());
  const [filesToUpload, setFilesToUpload] = useState(null);

  const [leavePauseDialogOpen, setLeavePauseDialogOpen] = useState(false);

  const [uploadFilesVisible, setUploadFilesVisible] = useState(false);

  const [cloudFilesVisible, setCloudFilesVisible] = useState(false);
  const [filesCloudList, setFliesCloudList] = useState([]);

  const [pathDialogOpen, setPathDialogOpen] = useState(false);
  const [editContactDialogOpen, setEditContactDialogOpen] = useState(false);
  const [reminderDialogShow, setReminderDialogShow] = useState(false);
  const [searchForContactDialogShow, setSearchForContactDialogShow] =
    useState(false);
  const [reminderDialogWithNumberShow, setReminderDialogWithNumberShow] =
    useState(false);
  const [pauseDialogShow, setPauseDialogShow] = useState(false);
  const [notesDialogShow, setNotesDialogShow] = useState(false);
  const [scheduleDialogShow, setScheduleDialogShow] = useState(false);
  const [scheduleChatDialogShow, setScheduleChatDialogShow] = useState(false);
  const [schedule, setSchedule] = useState([]);
  const [nextOperatorDialog, setNextOperatorDialog] = useState(false);
  const [closeConfirmDialog, setCloseConfirmDialog] = useState(false);
  const [profileOperatorDialogOpen, setProfileOperatorDialogOpen] =
    useState(false);
  const [recordAudioDialogOpen, setRecordAudioDialogOpen] = useState(false);
  const [blockSendingAudio, setBlockSendingAudio] = useState(false);
  const [profilePicOpen, setProfilePicOpen] = useState(false);
  const [contactsPerDateDialogShow, setContactsPerDateDialogShow] =
    useState(false);

  // useEffect(() => {
  //     if (pathDialogOpen) {
  //         dispatch(getChatHistory(prepareData(selectedChat.id)))
  //     }
  // }, [pathDialogOpen])

  const _setPathDialogOpen = () => {
    dispatch(getChatHistory(prepareData(selectedChat.id)))
      .then(unwrapResult)
      .then(setPathDialogOpen(true));
  };

  const prepareData = (id) => {
    return {
      access_click2chat: user,
      chat_id: id,
      files: localFiles ? Object.keys(localFiles) : [],
      fetchingChats,
    };
  };

  const _setSelectedChat = (chat) => {
    if (
      !fetchingChat &&
      ((selectedChat && chat._id !== selectedChat.id) || !selectedChat)
    ) {
      dispatch(setFetchingChat(chat._id));
      dispatch(setSelectedChat(chat));
      dispatch(getChat(prepareData(chat._id)));
    }
  };

  const handleSendMessage = (
    chat_id,
    msg,
    hasMedia,
    file_id,
    team_id,
    team_name
  ) => {
    let access_click2chat = createAccess(user);
    API.post.message(
      access_click2chat,
      chat_id,
      msg,
      hasMedia,
      file_id,
      team_id,
      team_name
    );
  };

  const _setScheduleDialogShow = () => {
    let access_click2chat = createAccess(user);
    setScheduleDialogShow(true);
    API.get
      .scheduleOperator(access_click2chat)
      .then((response) => {
        if (response?.ok && response?.data?.info) {
          setSchedule(response.data.info);
        } else {
          setSchedule([]);
        }
      })
      .finally(() => {});
  };

  const handleDeleteReminder = (reminder_id) => {
    let access_click2chat = createAccess(user);
    API.delete
      .schedule(access_click2chat, reminder_id)
      .then((response) => {
        if (response?.ok && response?.data?.info && response?.status === 202) {
          setSchedule(response.data.info);
        }
      })
      .finally(() => {});
  };

  const handleResendReminder = (reminder_id) => {
    let access_click2chat = createAccess(user);
    API.put
      .schedule(access_click2chat, reminder_id)
      .then((response) => {
        if (response?.ok && response?.data?.info && response?.status === 202) {
          setSchedule(response.data.info);
        }
      })
      .finally(() => {});
  };

  const handlePutContactName = (
    contact_name,
    cpf_cnpj,
    email,
    phone_number
  ) => {
    let access_click2chat = createAccess(user);
    setEditContactDialogOpen(false);
    API.put.contactName(
      access_click2chat,
      contact_name,
      cpf_cnpj,
      email,
      phone_number
    );
  };

  const handleLazyDownload = async (media_id, media_key) => {
    let access_click2chat = createAccess(user);
    let file = await API.get.file(access_click2chat, media_id, media_key);
    if (file.ok && file.data && file.data.data) {
      let url = b64toBlob(file.data.data.file, file.data.data.mime_type);
      let filename = file.data.data.filename;
      downloadURI(url, filename);
    }
  };

  const handleLazyOpen = async (media_id, media_key) => {
    let access_click2chat = createAccess(user);
    let file = await API.get.file(access_click2chat, media_id, media_key);
    if (file.ok && file.data && file.data.data) {
      let url = b64toBlob(file.data.data.file, file.data.data.mime_type);
      openURI(url);
    }
  };

  const handleSendFile = async ({
    chat_id,
    filename,
    resend,
    file_id,
    fileIndex,
    sendMessage,
    team_id,
    team_name,
  }) => {
    let access_click2chat = createAccess(user);

    if (resend) {
      API.post.fileResend(
        access_click2chat,
        chat_id,
        filename,
        resend,
        file_id
      );
    } else {
      let FTU = filesToUpload;
      FTU.rejectedFiles = [];
      if (
        !FTU.acceptedFiles[fileIndex]['status'] ||
        (FTU.acceptedFiles[fileIndex]['status'] &&
          FTU.acceptedFiles[fileIndex]['status'] !== 'done')
      ) {
        FTU.acceptedFiles[fileIndex]['status'] = 'loading';
        setFilesToUpload(FTU);
        let id = await FTU.acceptedFiles[fileIndex].filePromise
          .then((result) => {
            // dispatch or do whatever you need with result
            let _file = {
              mediaKey: '',
              filename: FTU.acceptedFiles[fileIndex].name,
              mime_type: result.split(';')[0].split(':')[1],
              metadata_message: {},
              file_size: `${(result.split(',')[1].length / 1e6).toFixed(2)} mb`,
              timestamp: new Date(),
              file: result.split(',')[1],
            };
            return API.post
              .file(access_click2chat, _file)
              .then((response) => {
                if (response.ok) {
                  FTU.acceptedFiles[fileIndex]['status'] = 'done';
                  setFilesToUpload(FTU);
                  if (sendMessage) {
                    handleSendMessage(
                      chat_id,
                      FTU.acceptedFiles[fileIndex].name,
                      true,
                      response.data.info.id,
                      team_id,
                      team_name
                    );
                  }
                  return response.data.info;
                } else {
                  throw new Error('Failed');
                }
              })
              .catch(() => {
                FTU.acceptedFiles[fileIndex]['status'] = 'failed';
                setFilesToUpload(FTU);
              });
          })
          .catch(() => {
            FTU.acceptedFiles[fileIndex]['status'] = 'failed';
            setFilesToUpload(FTU);
          });
        return id;
      }
    }
  };

  const handleSendAudio = async ({ base64 }) => {
    setBlockSendingAudio(true);
    let access_click2chat = createAccess(user);
    let mime_type = base64.split(';')[0].split(':')[1];
    let extension = mime_type.split('/')[1];
    let filename = `${makeid(15)}.${extension}`;
    let _file = {
      mediaKey: '',
      filename,
      mime_type,
      metadata_message: {},
      file_size: `${(base64.split(',')[1].length / 1e6).toFixed(2)} mb`,
      timestamp: new Date(),
      file: base64.split(',')[1],
    };
    API.post
      .file(access_click2chat, _file)
      .then((response) => {
        if (response.ok) {
          handleSendMessage(
            selectedChat.id,
            filename,
            true,
            response.data.info.id
          );
        } else {
          throw new Error('Failed');
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setRecordAudioDialogOpen(false);
        setBlockSendingAudio(false);
      });
  };

  const handleSendCloudFile = ({ fileId, filename }) => {
    setCloudFilesVisible(false);
    handleSendMessage(selectedChat.id, filename, true, fileId);
  };

  const handleFavorite = (star, phone_number) => {
    let access_click2chat = createAccess(user);
    API.put.star(access_click2chat, star, phone_number);
  };

  const getFilesCloudList = () => {
    let access_click2chat = createAccess(user);
    API.get
      .filesCloudList(access_click2chat)
      .then((response) => {
        if (response.ok) {
          setFliesCloudList(response.data.filesCloudList);
        } else {
          setFliesCloudList([]);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {});
  };

  const onDropFile = (acceptedFiles, rejectedFiles) => {
    console.log('onDropFile', { acceptedFiles, rejectedFiles });
    if (filesToUpload) {
      let FTU = filesToUpload;
      let filesToAccept = [];
      if (Array.isArray(acceptedFiles) && acceptedFiles.length > 0) {
        for (let i = 0; i <= acceptedFiles.length - 1; i++) {
          let _file = acceptedFiles[i];
          console.log({ _file, acceptedFiles });
          if (!_file.pasted) {
            _file.filePromise = fileTob64(_file);
          }
          filesToAccept.push(_file);
          console.log({ filesToAccept });
        }
      }

      FTU.acceptedFiles = FTU.acceptedFiles.concat(filesToAccept);
      FTU.rejectedFiles = FTU.rejectedFiles.concat(rejectedFiles);
      setFilesToUpload(FTU);
    } else {
      let filesToAccept = [];
      if (Array.isArray(acceptedFiles) && acceptedFiles.length > 0) {
        for (let i = 0; i <= acceptedFiles.length - 1; i++) {
          let _file = acceptedFiles[i];
          if (!_file.pasted) {
            _file.filePromise = fileTob64(_file);
          }
          filesToAccept.push(_file);
        }
      }
      setFilesToUpload({ acceptedFiles: filesToAccept, rejectedFiles });
    }
  };

  const handlePaste = async (event) => {
    try {
      if (!navigator.clipboard) {
        console.error('Clipboard API not available');
        return;
      }

      const clipboardItems = await navigator.clipboard.read();
      for (const clipboardItem of clipboardItems) {
        const imageTypes = clipboardItem.types.find((type) =>
          type.startsWith('image/')
        );
        console.log({ imageTypes });

        if (
          imageTypes &&
          (acceptedFiles.includes(imageTypes.split('/')[1]) ||
            acceptedFiles.includes('jpeg') ||
            acceptedFiles.includes('jpg'))
        ) {
          const blob = await clipboardItem.getType(imageTypes);
          const url = URL.createObjectURL(blob);
          console.log('Pasted image:', { url, blob });

          onDropFile(
            [
              {
                filePromise: new Promise((resolve) => {
                  let reader = new FileReader();
                  reader.readAsDataURL(blob);
                  reader.onload = function () {
                    let base64data = reader.result;
                    resolve(base64data);
                  };
                }),
                name: `${new Date().toISOString()}-screenshot.png`,
                type: imageTypes,
                pasted: true,
              },
            ],
            []
          );
          break; // Assuming we need the first image
        }
      }
    } catch (err) {
      console.error('Failed to read clipboard:', err);
    }
  };

  const onChangeFile = (e) => {
    // console.log('onChangeFile', e);
  };

  const removeFileFromUpload = (index, list) => {
    if (list === 'rejectedFiles') {
      let FTU = filesToUpload;
      delete FTU.rejectedFiles[index];
      FTU.rejectedFiles = FTU.rejectedFiles.filter((el) => el !== null);
      setFilesToUpload(FTU);
    } else if (list === 'acceptedFiles') {
      let FTU = filesToUpload;
      delete FTU.acceptedFiles[index];
      FTU.acceptedFiles = FTU.acceptedFiles.filter((el) => el !== null);
      setFilesToUpload(FTU);
    }
  };

  useEffect(() => {
    dispatch(getChats(user));
    const interval = setInterval(() => {
      if (getNewData.current) {
        dispatch(getChats(user));
      }
    }, timeToUpdate);

    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (allChatsLists?.new?.length > 0) {
      document.title = `(${allChatsLists?.new?.length}) Click2Chat`;
    } else {
      document.title = `Click2Chat`;
    }
  }, [allChatsLists?.new]);

  useEffect(() => {
    getNewData.current = !fetchingChats;
  }, [fetchingChats]);

  useEffect(() => {
    if (selectedChat && selectedChat.id) {
      dispatch(setFetchingChat(selectedChat.id));
      dispatch(getChat(prepareData(selectedChat.id)));
    }
    // eslint-disable-next-line
  }, [lastInteractionTime, selectedChatList]);

  useEffect(() => {
    if (selectedChat && selectedChat.last_interaction.last_interaction_time) {
      setLastInteractionTime(
        selectedChat.last_interaction.last_interaction_time
      );
    }
  }, [selectedChat]);

  useEffect(() => {
    if (
      filesToUpload &&
      !(reminderDialogShow || reminderDialogWithNumberShow)
    ) {
      setUploadFilesVisible(true);
    }
    // eslint-disable-next-line
  }, [filesToUpload]);

  useEffect(() => {
    if (!uploadFilesVisible) {
      setFilesToUpload(null);
    }
  }, [uploadFilesVisible]);

  useEffect(() => {
    if (!reminderDialogShow || !reminderDialogWithNumberShow) {
      setUploadFilesVisible(false);
      setFilesToUpload(null);
    }
  }, [reminderDialogShow, reminderDialogWithNumberShow]);

  useEffect(() => {
    if (!scheduleDialogShow) {
      setSchedule([]);
    }
  }, [scheduleDialogShow]);

  useEffect(() => {
    if (statusPause) {
      setLeavePauseDialogOpen(true);
    } else {
      setLeavePauseDialogOpen(false);
    }
  }, [statusPause]);

  useEffect(() => {
    if (cloudFilesVisible) {
      getFilesCloudList();
    }
    // eslint-disable-next-line
  }, [cloudFilesVisible]);

  // useEffect(() => {
  //   console.log({ _chatsHash });
  //   setChatsList(chatsList);
  // }, [_chatsHash]);

  return (
    <>
      <NotificationBar />
      <OperationBar
        setReminderDialogShow={setReminderDialogShow}
        setNotesDialogShow={setNotesDialogShow}
        setScheduleDialogShow={_setScheduleDialogShow}
        handleDeleteReminder={handleDeleteReminder}
        handleResendReminder={handleResendReminder}
        setPauseDialogShow={setPauseDialogShow}
        setProfileOperatorDialogOpen={setProfileOperatorDialogOpen}
        setSearchForContactDialogShow={setSearchForContactDialogShow}
        setContactsPerDateDialogShow={setContactsPerDateDialogShow}
      />
      <ChatsStructure
        onPaste={handlePaste}
        chatsList={chatsList}
        selectedChat={selectedChat}
        setSelectedChat={_setSelectedChat}
        handleSendMessage={handleSendMessage}
        handleSendFile={handleSendFile}
        files={localFiles}
        notes={notes}
        acceptedFiles={acceptedFiles}
        handleFavorite={handleFavorite}
        filesToUpload={filesToUpload}
        setFilesToUpload={setFilesToUpload}
        uploadFilesVisible={uploadFilesVisible}
        setUploadFilesVisible={setUploadFilesVisible}
        onDropFile={onDropFile}
        onChangeFile={onChangeFile}
        setPathDialogOpen={_setPathDialogOpen}
        setEditContactDialogOpen={setEditContactDialogOpen}
        setNextOperatorDialog={setNextOperatorDialog}
        setReminderDialogWithNumberShow={setReminderDialogWithNumberShow}
        setCloseConfirmDialog={setCloseConfirmDialog}
        setScheduleChatDialogShow={setScheduleChatDialogShow}
        handleLazyDownload={handleLazyDownload}
        handleLazyOpen={handleLazyOpen}
        setRecordAudioDialogOpen={setRecordAudioDialogOpen}
        setCloudFilesVisible={setCloudFilesVisible}
        cloudFilesVisible={cloudFilesVisible}
        setProfilePicOpen={setProfilePicOpen}
        operation={operation}
      />
      {selectedChat && selectedChat.id && uploadFilesVisible && (
        <FilesDialog
          open={uploadFilesVisible}
          setOpen={setUploadFilesVisible}
          filesToUpload={filesToUpload}
          setFilesToUpload={setFilesToUpload}
          onDropFile={onDropFile}
          onChangeFile={onChangeFile}
          acceptedtFileTypes={acceptedFiles}
          removeFileFromUpload={removeFileFromUpload}
          handleSendFile={handleSendFile}
          selectedChatId={
            selectedChat && selectedChat.id ? selectedChat.id : ''
          }
        />
      )}
      {selectedChat && selectedChat.id && cloudFilesVisible && (
        <CloudFilesDialog
          open={cloudFilesVisible}
          setOpen={setCloudFilesVisible}
          filesCloudList={filesCloudList}
          handleSendCloudFile={handleSendCloudFile}
        />
      )}
      {selectedChat &&
        selectedChat.id &&
        selectedChat.path &&
        pathDialogOpen && (
          <PathDialog
            open={pathDialogOpen}
            setOpen={setPathDialogOpen}
            path={selectedChat.path}
            phoneNumber={selectedChat.last_interaction.phone_number}
          />
        )}
      {selectedChat && selectedChat.id && editContactDialogOpen && (
        <EditContactDialog
          open={editContactDialogOpen}
          setOpen={setEditContactDialogOpen}
          contactName={selectedChat.last_interaction.user}
          contactEmail={selectedChat.last_interaction.email}
          contactCpfCnpj={selectedChat.last_interaction.cpf_cnpj}
          handlePutContactName={handlePutContactName}
          phone_number={selectedChat.last_interaction.phone_number}
        />
      )}
      {selectedChat && selectedChat.id && nextOperatorDialog && (
        <NextOperatorDialog
          open={nextOperatorDialog}
          setOpen={setNextOperatorDialog}
          phone_number={selectedChat.last_interaction.phone_number}
        />
      )}

      {selectedChat && selectedChat.id && closeConfirmDialog && (
        <CloseConfirmDialog
          open={closeConfirmDialog}
          setOpen={setCloseConfirmDialog}
          phoneNumber={selectedChat.last_interaction.phone_number}
          closeReasonList={closeReasonList}
          setReminder={setReminderDialogWithNumberShow}
        />
      )}
      {reminderDialogShow && (
        <ReminderDialog
          open={reminderDialogShow}
          setOpen={setReminderDialogShow}
          hourSelectedReminder={hourSelectedReminder}
          setHourSelectedReminder={setHourSelectedReminder}
          notes={notes}
          filesToUpload={filesToUpload}
          onDropFile={onDropFile}
          onChangeFile={onChangeFile}
          acceptedtFileTypes={acceptedFiles}
          removeFileFromUpload={removeFileFromUpload}
          handleSendFile={handleSendFile}
          setSchedule={setSchedule}
        />
      )}
      {reminderDialogWithNumberShow && selectedChat.id && (
        <ReminderDialog
          open={reminderDialogWithNumberShow}
          setOpen={setReminderDialogWithNumberShow}
          phone_number={selectedChat.last_interaction.phone_number}
          hourSelectedReminder={hourSelectedReminder}
          chat_id={selectedChat && selectedChat.id ? selectedChat.id : ''}
          setHourSelectedReminder={setHourSelectedReminder}
          removeFileFromUpload={removeFileFromUpload}
          notes={notes}
          filesToUpload={filesToUpload}
          onDropFile={onDropFile}
          onChangeFile={onChangeFile}
          acceptedtFileTypes={acceptedFiles}
          setSchedule={setSchedule}
          handleSendFile={handleSendFile}
        />
      )}
      {scheduleChatDialogShow && selectedChat.id && (
        <ScheduleChatDialog
          open={scheduleChatDialogShow}
          setOpen={setScheduleChatDialogShow}
          phone_number={selectedChat.last_interaction.phone_number}
        />
      )}
      {notesDialogShow && (
        <NotesDialog
          open={notesDialogShow}
          setOpen={setNotesDialogShow}
          notes={notes}
        />
      )}
      {scheduleDialogShow && (
        <ScheduleDialog
          open={scheduleDialogShow}
          setOpen={setScheduleDialogShow}
          schedule={schedule}
          setOpenReminderDialog={setReminderDialogShow}
          handleResendReminder={handleResendReminder}
          handleDeleteReminder={handleDeleteReminder}
        />
      )}
      {pauseDialogShow && (
        <PauseDialog
          open={pauseDialogShow}
          setOpen={setPauseDialogShow}
          user={user}
          statusPause={statusPause}
        />
      )}
      {leavePauseDialogOpen && (
        <LeavePauseDialog
          open={leavePauseDialogOpen}
          setOpen={setLeavePauseDialogOpen}
          user={user}
        />
      )}
      {profileOperatorDialogOpen && (
        <ProfileOperatorDialog
          open={profileOperatorDialogOpen}
          setOpen={setProfileOperatorDialogOpen}
          user={user}
        />
      )}
      {recordAudioDialogOpen && (
        <RecordAudioDialog
          open={recordAudioDialogOpen}
          setOpen={setRecordAudioDialogOpen}
          user={user}
          handleSendAudio={handleSendAudio}
          blockSendingAudio={blockSendingAudio}
        />
      )}
      {searchForContactDialogShow && (
        <SearchForContactDialog
          open={searchForContactDialogShow}
          setOpen={setSearchForContactDialogShow}
          user={user}
        />
      )}
      {contactsPerDateDialogShow && (
        <ContactsPerDateDialog
          open={contactsPerDateDialogShow}
          setOpen={setContactsPerDateDialogShow}
          user={user}
        />
      )}
      {profilePicOpen && (
        <ProfilePicOpenDialog
          open={profilePicOpen}
          setOpen={setProfilePicOpen}
          profilePicURL={selectedChat?.profile_pic_url}
        />
      )}
    </>
  );
}
