// React
import React, { useEffect, useState, useRef } from 'react';

// Design
import {
  Box,
  Dialog,
  DialogTitle,
  DialogActions,
  TextField,
  Button,
  List,
  ListItemButton,
  ListItemText,
  ListItemIcon,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import CloseIcon from '@mui/icons-material/Close';

// Internal
import Supervision from '../..';
import Filters from './Components/filters';
import FilterField from './Components/filterField';
import {
  appSupervision,
  appUser,
  setFilteredChat,
  setFilteredChatsList,
  setFilterInterval,
  setFilterKeyWord,
  setFilterType,
} from '../../../../store/app/slice';
import createAccess from '../../../../utils/functions/createAccess';
import API from '../../../../utils/api';
import FilteredList from './Components/filteredList';
import FilteredChatMenu from './Components/filteredChatMenu';
import FilteredChatMessages from './Components/filteredChatMessages';
import downloadURI from '../../../../utils/functions/downloadURI';
import b64ToBlob from '../../../../utils/functions/b64toBlob';
import PathDialog from '../../../../components/Dialogs/pathDialog';
import NextOperatorDialog from '../../../../components/Dialogs/nextOperatorDialog';
import openURI from '../../../../utils/functions/openURI';
import CloseConfirmDialog from '../../../../components/Dialogs/closeConfirmDialog';
import objDeepCopy from '../../../../utils/functions/objDeepCopy';
import SendMessageBox from '../../../../components/Boxes/sendMessageBox';
import FilesDialog from '../../../../components/Dialogs/filesDialog';
import fileTob64 from '../../../../utils/functions/filetob64';
import RecordAudioDialog from '../../../../components/Dialogs/recordAudioDialog';
import InfoContactDialog from '../../../../components/Dialogs/infoContactDialog';

import makeid from '../../../../utils/functions/makeid';

// Third-party
import { useSelector, useDispatch } from 'react-redux';
import SendWhisperBox from '../../../../components/Boxes/sendWhisperBox';

// create a dialog that will list the selected chats in the left and in the right will have a text field to send a message to them all
function NotificationDialog({ open, setOpen, selectedChats = [], user }) {
  const [message, setMessage] = useState('');
  const [selected, setSelected] = useState([]);
  const [blockSend, setBlockSend] = useState(false);
  const removeFromList = (id) => {
    setSelected((prev) => prev.filter((chat) => chat._id !== id));
  };

  const resetNumbers = () => {
    setSelected(selectedChats);
  };

  const handleSendMessage = () => {
    setBlockSend(true);
    // send message to selectedChats
    let access_click2chat = createAccess(user);
    let numbers = selected.map((chat) => chat.phone_number);
    let obj = {
      access_click2chat,
      numbers,
      message,
    };
    API.put
      .notifyUsers({ ...obj })
      .then()
      .finally(() => setBlockSend(false));
  };

  useEffect(() => {
    if (open) {
      setSelected(selectedChats);
    } else {
      setSelected([]);
      setBlockSend(false);
    }
  }, [open]);

  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogTitle>Enviar mensagem para os chats selecionados</DialogTitle>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          margin: '1rem',
        }}
      >
        <Box>
          <List>
            {selected.map((chat) => (
              <ListItemButton key={chat._id}>
                <ListItemIcon>
                  <CloseIcon
                    color="error"
                    onClick={() => removeFromList(chat._id)}
                  />
                </ListItemIcon>
                <ListItemText primary={`${chat.phone_number}`} />
              </ListItemButton>
            ))}
          </List>
        </Box>
        <TextField
          label="Messagem"
          multiline
          rows={4}
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          disabled={
            !Array.isArray(selected) ||
            (Array.isArray(selected) && selected.length === 0)
          }
        />
      </Box>
      <DialogActions>
        {!(selected?.length === selectedChats?.length) && (
          <Button
            onClick={resetNumbers}
            disabled={selected?.length === selectedChats?.length}
          >
            Adicionar todos os números
          </Button>
        )}

        <Button onClick={() => setOpen(false)} color="error">
          Cancelar
        </Button>
        <Button
          onClick={handleSendMessage}
          disabled={
            !Array.isArray(selected) ||
            (Array.isArray(selected) && selected.length === 0) ||
            !message ||
            blockSend
          }
        >
          Enviar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default function ChatsMonitoring() {
  const timeToUpdate = 2000;

  const dispatch = useDispatch();
  const user = useSelector(appUser);
  const _appSupervision = useSelector(appSupervision);
  const worker = user.worker;
  const selectedChat = useSelector(appSupervision)?.filteredChat ?? null;
  const [messages, setMessages] = useState([]);
  const [path, setPath] = useState([]);
  const [currentOperator, setCurrentOperator] = useState('');
  const [loadingChats, setLoadingChats] = useState(false);
  const [openPathDialog, setOpenPathDialog] = useState(false);
  const [nextOperatorDialog, setNextOperatorDialog] = useState(false);
  const [closeConfirmDialog, setCloseConfirmDialog] = useState(false);
  const [recordAudioDialogOpen, setRecordAudioDialogOpen] = useState(false);
  const [openInfoDialog, setOpenInfoDialog] = useState(false);

  const [blockSendingAudio, setBlockSendingAudio] = useState(false);

  const [isChatOpen, setIsChatOpen] = useState(false);
  const [isChatBlocked, setIsChatBlocked] = useState(false);
  const [isChatLoading, setIsChatLoading] = useState(false);
  const [closeReasonsList, setCloseReasonsList] = useState([]);
  const [chatVM, setChatVM] = useState('');
  const [_interval, _setInterval] = useState(null);
  const [whisperBox, setWhisperBox] = useState(false);
  const [keyWords, _setKeyWords] = useState('');

  const [openNotificationDialog, setOpenNotificationDialog] = useState(false);

  const keyWordsRef = useRef('');
  const setKeyWords = (newValue) => {
    _setKeyWords(newValue);
    keyWordsRef.current = newValue;
  };
  const [_filterType, __setFilterType] = useState('');
  const _filterTypeRef = useRef('');
  const _setFilterType = (newValue) => {
    __setFilterType(newValue);
    _filterTypeRef.current = newValue;
  };

  const [filesToUpload, setFilesToUpload] = useState(null);
  const notes = useSelector(appSupervision)?.notes;
  const acceptedFiles = useSelector(appSupervision)?.accepted_files;
  const [uploadFilesVisible, setUploadFilesVisible] = useState(false);

  const handleSelectChat = (chat, loading = true) => {
    if (chat) {
      let _chat = objDeepCopy(chat);
      _chat['id'] = chat?.chat_id;
      if (loading) {
        setIsChatLoading(true);
      }
      // setSelectedChat(_chat)
      dispatch(setFilteredChat(_chat));
    }
  };

  const removeFileFromUpload = (index, list) => {
    if (list === 'rejectedFiles') {
      let FTU = filesToUpload;
      delete FTU.rejectedFiles[index];
      FTU.rejectedFiles = FTU.rejectedFiles.filter((el) => el !== null);
      setFilesToUpload(FTU);
    } else if (list === 'acceptedFiles') {
      let FTU = filesToUpload;
      delete FTU.acceptedFiles[index];
      FTU.acceptedFiles = FTU.acceptedFiles.filter((el) => el !== null);
      setFilesToUpload(FTU);
    }
  };

  const handleSendAudio = async ({ base64 }) => {
    setBlockSendingAudio(true);
    let access_click2chat = createAccess(user);
    let mime_type = base64.split(';')[0].split(':')[1];
    let extension = mime_type.split('/')[1];
    let filename = `${makeid(15)}.${extension}`;
    let _file = {
      mediaKey: '',
      filename,
      mime_type,
      metadata_message: {},
      file_size: `${(base64.split(',')[1].length / 1e6).toFixed(2)} mb`,
      timestamp: new Date(),
      file: base64.split(',')[1],
    };
    API.post
      .file(access_click2chat, _file)
      .then((response) => {
        if (response.ok) {
          handleSendMessage(
            selectedChat.id,
            filename,
            true,
            response.data.info.id
          );
        } else {
          throw new Error('Failed');
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setRecordAudioDialogOpen(false);
        setBlockSendingAudio(false);
      });
  };

  const getActiveFilter = (keyWords, filterType) => {
    // dispatch(setFilterKeyWord(keyWords));
    let access_click2chat = createAccess(user);
    setCurrentOperator('');
    // setLoadingChats(true);
    if (filterType === 'name') {
      API.get
        .chatsByName(access_click2chat, keyWords)
        .then((response) => {
          if (response?.data?.info?.chats) {
            dispatch(setFilteredChatsList(response.data.info.chats));
          } else {
            dispatch(setFilteredChatsList([]));
          }
        })
        .finally(() => {
          // setOperatorEditDialogShow(false);
          setLoadingChats(false);
        });
    } else if (filterType === 'opened') {
      if (keyWords) {
        API.get
          .chatsByUser(access_click2chat, keyWords)
          .then((response) => {
            if (response?.data?.info?.chats) {
              dispatch(setFilteredChatsList(response.data.info.chats));
            } else {
              dispatch(setFilteredChatsList([]));
            }
          })
          .finally(() => {
            setLoadingChats(false);
            // setOperatorEditDialogShow(false);
          });
      }
    } else if (filterType === 'closedXoperator') {
      if (keyWords) {
        API.get
          .chatsByCloseXOperator(access_click2chat, keyWords)
          .then((response) => {
            if (response?.data?.info?.chats) {
              dispatch(setFilteredChatsList(response.data.info.chats));
            } else {
              dispatch(setFilteredChatsList([]));
            }
          })
          .finally(() => {
            setLoadingChats(false);
            // setOperatorEditDialogShow(false);
          });
      }
    } else if (filterType === 'starred') {
      if (keyWords) {
        API.get
          .chatsByUser(access_click2chat, keyWords, true)
          .then((response) => {
            if (response?.data?.info?.chats) {
              dispatch(setFilteredChatsList(response.data.info.chats));
            } else {
              dispatch(setFilteredChatsList([]));
            }
          })
          .finally(() => {
            setLoadingChats(false);
            // setOperatorEditDialogShow(false);
          });
      }
    } else if (filterType === 'inbox') {
      API.get
        .chatsBySupervisor(access_click2chat)
        .then((response) => {
          if (response?.data?.info?.chats) {
            dispatch(setFilteredChatsList(response.data.info.chats));
          } else {
            dispatch(setFilteredChatsList([]));
          }
        })
        .finally(() => {
          setLoadingChats(false);
          // setOperatorEditDialogShow(false);
        });
      setCurrentOperator(user?.user_id);
    } else if (filterType === 'teams') {
      if (keyWords) {
        API.get
          .chatsByTeams(access_click2chat, keyWords)
          .then((response) => {
            if (response?.data?.info?.chats) {
              dispatch(setFilteredChatsList(response.data.info.chats));
            } else {
              dispatch(setFilteredChatsList([]));
            }
          })
          .finally(() => {
            setLoadingChats(false);
            // setOperatorEditDialogShow(false);
          });
      }
    } else if (filterType === 'lines') {
      if (keyWords) {
        API.get
          .chatsInLines(access_click2chat, keyWords)
          .then((response) => {
            if (response?.data?.info?.chats) {
              dispatch(setFilteredChatsList(response.data.info.chats));
            } else {
              dispatch(setFilteredChatsList([]));
            }
          })
          .finally(() => {
            setLoadingChats(false);
            // setOperatorEditDialogShow(false);
          });
      }
    }
  };

  const handleSendMessage = (
    chat_id,
    msg,
    hasMedia,
    file_id,
    team_id,
    team_name
  ) => {
    let access_click2chat = createAccess(user);
    API.post.message(
      access_click2chat,
      chat_id,
      msg,
      hasMedia,
      file_id,
      team_id,
      team_name
    );
  };

  const handleSendWhisper = (chat_id, msg) => {
    let access_click2chat = createAccess(user);
    API.post.whisper(access_click2chat, chat_id, msg);
  };

  const handleSendFile = async ({
    chat_id,
    filename,
    resend,
    file_id,
    fileIndex,
    sendMessage,
    team_id,
    team_name,
  }) => {
    let access_click2chat = createAccess(user);

    if (resend) {
      API.post.fileResend(
        access_click2chat,
        chat_id,
        filename,
        resend,
        file_id
      );
    } else {
      let FTU = filesToUpload;
      FTU.rejectedFiles = [];
      if (
        !FTU.acceptedFiles[fileIndex]['status'] ||
        (FTU.acceptedFiles[fileIndex]['status'] &&
          FTU.acceptedFiles[fileIndex]['status'] !== 'done')
      ) {
        FTU.acceptedFiles[fileIndex]['status'] = 'loading';
        setFilesToUpload(FTU);
        let id = await FTU.acceptedFiles[fileIndex].filePromise
          .then((result) => {
            // dispatch or do whatever you need with result
            let _file = {
              mediaKey: '',
              filename: FTU.acceptedFiles[fileIndex].name,
              mime_type: result.split(';')[0].split(':')[1],
              metadata_message: {},
              file_size: `${(result.split(',')[1].length / 1e6).toFixed(2)} mb`,
              timestamp: new Date(),
              file: result.split(',')[1],
            };
            return API.post
              .file(access_click2chat, _file)
              .then((response) => {
                if (response.ok) {
                  FTU.acceptedFiles[fileIndex]['status'] = 'done';
                  setFilesToUpload(FTU);
                  if (sendMessage) {
                    handleSendMessage(
                      chat_id,
                      FTU.acceptedFiles[fileIndex].name,
                      true,
                      response.data.info.id,
                      team_id,
                      team_name
                    );
                  }
                  return response.data.info;
                } else {
                  throw new Error('Failed');
                }
              })
              .catch(() => {
                FTU.acceptedFiles[fileIndex]['status'] = 'failed';
                setFilesToUpload(FTU);
              });
          })
          .catch(() => {
            FTU.acceptedFiles[fileIndex]['status'] = 'failed';
            setFilesToUpload(FTU);
          });
        return id;
      }
    }
  };

  const onDropFile = (acceptedFiles, rejectedFiles) => {
    console.log('onDropFile', { acceptedFiles, rejectedFiles });
    if (filesToUpload) {
      let FTU = filesToUpload;
      let filesToAccept = [];
      if (Array.isArray(acceptedFiles) && acceptedFiles.length > 0) {
        for (let i = 0; i <= acceptedFiles.length - 1; i++) {
          let _file = acceptedFiles[i];
          if (!_file.pasted) {
            _file.filePromise = fileTob64(_file);
          }
          filesToAccept.push(_file);
        }
      }

      FTU.acceptedFiles = FTU.acceptedFiles.concat(filesToAccept);
      FTU.rejectedFiles = FTU.rejectedFiles.concat(rejectedFiles);
      setFilesToUpload(FTU);
    } else {
      let filesToAccept = [];
      if (Array.isArray(acceptedFiles) && acceptedFiles.length > 0) {
        for (let i = 0; i <= acceptedFiles.length - 1; i++) {
          let _file = acceptedFiles[i];
          if (!_file.pasted) {
            _file.filePromise = fileTob64(_file);
          }
          filesToAccept.push(_file);
        }
      }
      setFilesToUpload({ acceptedFiles: filesToAccept, rejectedFiles });
    }
  };

  const handlePaste = async (event) => {
    try {
      if (!navigator.clipboard) {
        console.error('Clipboard API not available');
        return;
      }

      const clipboardItems = await navigator.clipboard.read();
      for (const clipboardItem of clipboardItems) {
        const imageTypes = clipboardItem.types.find((type) =>
          type.startsWith('image/')
        );

        if (
          imageTypes &&
          (acceptedFiles.includes(imageTypes.split('/')[1]) ||
            acceptedFiles.includes('jpeg') ||
            acceptedFiles.includes('jpg'))
        ) {
          const blob = await clipboardItem.getType(imageTypes);
          // const url = URL.createObjectURL(blob);

          onDropFile(
            [
              {
                filePromise: new Promise((resolve) => {
                  let reader = new FileReader();
                  reader.readAsDataURL(blob);
                  reader.onload = function () {
                    let base64data = reader.result;
                    resolve(base64data);
                  };
                }),
                name: `${new Date().toISOString()}-screenshot.png`,
                type: imageTypes,
                pasted: true,
              },
            ],
            []
          );
          break; // Assuming we need the first image
        }
      }
    } catch (err) {
      console.error('Failed to read clipboard:', err);
    } finally {
      setUploadFilesVisible(true);
    }
  };
  const onChangeFile = (e) => {
    // console.log('onChangeFile', e);
  };

  const handlePutNextOperator = (operator_id) => {
    let access_click2chat = createAccess(user);
    API.put
      .nextOperatorSupervision(
        access_click2chat,
        operator_id,
        currentOperator,
        selectedChat.phone_number
      )
      .then(() => {
        // if (response.status === 200) {
        //   setCurrentList(response.data.info.chats)
        // }
        // else {
        //   setCurrentList([])
        // }
      })
      .finally(() => {
        // setOperatorEditDialogShow(false);
      });
  };

  const handlePutNextSupervisor = (supervisor_id) => {
    let access_click2chat = createAccess(user);
    API.put
      .nextSupervisorSupervision(
        access_click2chat,
        supervisor_id,
        currentOperator,
        selectedChat.phone_number
      )
      .then(() => {
        // if (response.status === 200) {
        //   setCurrentList(response.data.info.chats)
        // }
        // else {
        //   setCurrentList([])
        // }
      })
      .finally(() => {
        // setOperatorEditDialogShow(false);
      });
  };

  const handleLazyDownload = async (media_id, media_key) => {
    let access_click2chat = createAccess(user);
    let file = await API.get.file(access_click2chat, media_id, media_key);
    if (file.ok && file.data && file.data.data) {
      let url = b64ToBlob(file.data.data.file, file.data.data.mime_type);
      let filename = file.data.data.filename;
      downloadURI(url, filename);
    }
  };

  const handleBlockChat = (phone_number, status) => {
    let access_click2chat = createAccess(user);
    API.put
      .blockChat(access_click2chat, phone_number, !status)
      .then((response) => {
        if (response.ok) {
          setIsChatBlocked(!status);
        }
      });
  };

  const handleLazyOpen = async (media_id, media_key) => {
    let access_click2chat = createAccess(user);
    let file = await API.get.file(access_click2chat, media_id, media_key);
    if (file.ok && file.data && file.data.data) {
      let url = b64ToBlob(file.data.data.file, file.data.data.mime_type);
      openURI(url);
    }
  };

  const handleGetInfo = () => {
    if (_filterTypeRef.current && keyWordsRef.current) {
      getActiveFilter(keyWordsRef.current, _filterTypeRef.current);
    }
  };

  useEffect(() => {
    clearInterval(_interval);
    dispatch(setFilteredChatsList([]));
    dispatch(setFilterKeyWord(''));
    setMessages([]);
    dispatch(setFilteredChat(null));
    setFilesToUpload(null);
    setPath([]);
    setCurrentOperator('');
    setWhisperBox(false);
    // eslint-disable-next-line
  }, [_appSupervision?.filterType]);

  useEffect(() => {
    if (selectedChat && selectedChat.chat_id) {
      let access_click2chat = createAccess(user);
      API.get
        .chatSupervision(access_click2chat, selectedChat.chat_id)
        .then((response) => {
          if (response.status === 200) {
            let info = response?.data?.info;
            let closeReasons = info?.closed_reasons_list.filter(
              (el) => el.active
            );
            setMessages(info.chat);
            setPath(info.path);
            setCurrentOperator(info.current_operator);
            setIsChatOpen(info?.info_chat?.isChatOpen);
            setIsChatBlocked(info?.info_chat?.isChatBlocked);
            setChatVM(info?.info_chat?.chat_vm);
            setCloseReasonsList(closeReasons);
          } else {
            // setCurrentList([])
          }
        })
        .finally(() => {
          // setOperatorEditDialogShow(false);
          setIsChatLoading(false);
        });
    }
    // eslint-disable-next-line
  }, [selectedChat]);

  useEffect(() => {
    _setKeyWords('');
    clearInterval(_appSupervision?.filterInterval);
    _setInterval(null);
    dispatch(setFilterType(_filterTypeRef.current));
    setFilesToUpload(null);
    setUploadFilesVisible(false);
    // eslint-disable-next-line
  }, [_filterType]);

  useEffect(() => {
    dispatch(setFilterKeyWord(keyWordsRef.current));
    setUploadFilesVisible(false);
    setFilesToUpload(null);
    // eslint-disable-next-line
  }, [keyWords]);

  useEffect(() => {
    dispatch(setFilteredChat(null));
    setFilesToUpload(null);
    setUploadFilesVisible(false);
    // eslint-disable-next-line
  }, [_appSupervision?.filterKeyWord]);

  useEffect(() => {
    clearInterval(_interval);
    if (_filterTypeRef.current && keyWordsRef.current) {
      const interval = setInterval(() => {
        handleGetInfo();
      }, timeToUpdate);
      _setInterval(interval);
    }
    // eslint-disable-next-line
  }, [_appSupervision?.filterType, _appSupervision?.filterKeyWord]);

  useEffect(() => {
    // if (_interval) {
    dispatch(setFilterInterval(_interval));
    // }
    // eslint-disable-next-line
  }, [_interval]);

  useEffect(() => {
    if (_appSupervision?.filteredChatsList?.length > 0) {
      if (selectedChat) {
        let newChat = _appSupervision?.filteredChatsList.find(
          (el) =>
            el.chat_id === selectedChat.chat_id &&
            el.last_interaction_time !== selectedChat.last_interaction_time
        );
        if (newChat) {
          handleSelectChat(newChat, false);
        } else {
          newChat = _appSupervision?.filteredChatsList.find(
            (el) => el.chat_id === selectedChat.chat_id
          );
          if (newChat) {
          } else {
            setMessages([]);
            dispatch(setFilteredChat(null));
            setPath([]);
          }
        }
      }
    } else {
      setMessages([]);
      dispatch(setFilteredChat(null));
      setPath([]);
    }
    // eslint-disable-next-line
  }, [_appSupervision?.filteredChatsList]);

  useEffect(() => {
    dispatch(setFilterType(''));
    dispatch(setFilteredChatsList([]));
    dispatch(setFilterKeyWord(''));
    dispatch(setFilteredChat(null));
    setFilesToUpload(null);
    setUploadFilesVisible(false);
    clearInterval(_interval);
    setWhisperBox(false);
    return () => {
      clearInterval(_interval);
      dispatch(setFilterType(''));
      dispatch(setFilteredChatsList([]));
      dispatch(setFilterKeyWord(''));
      setUploadFilesVisible(false);
      dispatch(setFilteredChat(null));
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!uploadFilesVisible) {
      setFilesToUpload(null);
    }
  }, [uploadFilesVisible]);

  return (
    <>
      <Supervision />
      <Box
        sx={{
          display: 'flex',
          margin: 0,
          width: '100%',
          height: 'calc(100vh - 4.063rem)',
          overflowX: 'hidden',
        }}
        onPaste={handlePaste}
      >
        <Box
          sx={{
            width: '22rem',
            height: 'inherit',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              width: 'inherit',
              height: '3rem',
              alignItems: 'center',
            }}
          >
            <Filters setFilterType={_setFilterType} />
          </Box>
          <Box
            sx={{
              width: 'inherit',
              height: '12rem',
            }}
          >
            <FilterField
              user={user}
              getActiveFilter={getActiveFilter}
              loadingChats={loadingChats}
              keyWords={keyWords}
              setKeyWords={setKeyWords}
              openNotificationDialog={openNotificationDialog}
              setOpenNotificationDialog={setOpenNotificationDialog}
            />
          </Box>
          <Box
            sx={{
              width: 'inherit',
              height: 'calc(100% - 15rem)',
            }}
          >
            <FilteredList
              setSelectedChat={handleSelectChat}
              selectedChat={selectedChat}
            />
          </Box>
        </Box>
        <Box
          display="flex"
          sx={{
            flexDirection: 'column',
            height: 'calc(100vh - 4.163rem)',
            width: 'calc(100%)',
            overflow: 'hidden',
          }}
        >
          {selectedChat?.id && !isChatLoading ? (
            <>
              <Box
                display="flex"
                sx={{
                  width: 'calc(100%)',
                  height: '5rem',
                  background: '#BDBDBD',
                }}
              >
                <FilteredChatMenu
                  setOpenPathDialog={setOpenPathDialog}
                  setNextOperatorDialog={setNextOperatorDialog}
                  setCloseConfirmDialog={setCloseConfirmDialog}
                  isChatOpen={isChatOpen}
                  isChatBlocked={isChatBlocked}
                  currentVM={chatVM}
                  handleBlockChat={handleBlockChat}
                  phoneNumber={selectedChat.phone_number}
                  setWhisperBox={setWhisperBox}
                  whisperBox={whisperBox}
                  setOpenInfoDialog={setOpenInfoDialog}
                />
              </Box>
              <Box
                display="flex"
                sx={{
                  width: 'inherit',
                  height:
                    _appSupervision?.filterType === 'inbox'
                      ? 'calc(100% - 5rem - 10rem)'
                      : 'calc(100% - 5rem)',
                  // overflowY: 'scroll',
                  overflowX: 'hidden',
                }}
              >
                <FilteredChatMessages
                  messages={messages}
                  worker={worker}
                  handleLazyDownload={handleLazyDownload}
                  handleLazyOpen={handleLazyOpen}
                />
              </Box>
            </>
          ) : _appSupervision?.filterType && isChatLoading ? (
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                height: '100%',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <CircularProgress size={150} />
            </Box>
          ) : (
            ''
          )}
          {_appSupervision?.filterType === 'inbox' &&
            !isChatLoading &&
            selectedChat?.id && (
              <SendMessageBox
                handleSendMessage={handleSendMessage}
                chatId={selectedChat?.id}
                handleSendFile={handleSendFile}
                notes={notes}
                uploadFilesVisible={uploadFilesVisible}
                setUploadFilesVisible={setUploadFilesVisible}
                setRecordAudioDialogOpen={setRecordAudioDialogOpen}
              />
            )}
          {whisperBox && !isChatLoading && selectedChat?.id && (
            <SendWhisperBox
              handleSendWhisper={handleSendWhisper}
              chatId={selectedChat?.id}
            />
          )}
        </Box>
      </Box>
      {selectedChat?.id && path && openPathDialog && (
        <PathDialog
          open={openPathDialog}
          setOpen={setOpenPathDialog}
          path={path}
          phoneNumber={selectedChat.phone_number}
        />
      )}
      {selectedChat?.id && nextOperatorDialog && (
        <NextOperatorDialog
          open={nextOperatorDialog}
          setOpen={setNextOperatorDialog}
          phone_number={selectedChat.phone_number}
          _handlePutNextOperator={handlePutNextOperator}
          _handlePutNextSupervisor={handlePutNextSupervisor}
        />
      )}
      {selectedChat?.id && closeConfirmDialog && (
        <CloseConfirmDialog
          open={closeConfirmDialog}
          setOpen={setCloseConfirmDialog}
          phoneNumber={selectedChat.phone_number}
          closeReasonList={closeReasonsList}
        />
      )}
      {recordAudioDialogOpen && (
        <RecordAudioDialog
          open={recordAudioDialogOpen}
          setOpen={setRecordAudioDialogOpen}
          user={user}
          handleSendAudio={handleSendAudio}
          blockSendingAudio={blockSendingAudio}
        />
      )}
      {selectedChat?.id &&
        uploadFilesVisible &&
        _appSupervision?.filterType === 'inbox' &&
        !isChatLoading && (
          <FilesDialog
            open={uploadFilesVisible}
            setOpen={setUploadFilesVisible}
            filesToUpload={filesToUpload}
            setFilesToUpload={setFilesToUpload}
            onDropFile={onDropFile}
            onChangeFile={onChangeFile}
            acceptedtFileTypes={acceptedFiles}
            removeFileFromUpload={removeFileFromUpload}
            handleSendFile={handleSendFile}
            selectedChatId={selectedChat?.id}
          />
        )}
      {selectedChat?.id && openInfoDialog && (
        <InfoContactDialog
          open={openInfoDialog}
          setOpen={setOpenInfoDialog}
          info={selectedChat?.user_info}
        />
      )}
      <NotificationDialog
        open={openNotificationDialog}
        setOpen={setOpenNotificationDialog}
        selectedChats={_appSupervision?.filteredChatsList}
        user={user}
      />
    </>
  );
}
